import React, { useEffect, useState } from "react";
import { FaEdit, FaRupeeSign,FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavBar2 } from "./NavBar2";
import { MyDatePicker } from "./MyDatePicker";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import config from '../config';

interface Bid {
  game_id?: number;
  game_type?: string;
  open_digit: string;
  close_panna: string;
  bid_points: string;
  session: string;
  open_panna?: string;
  close_digit?: string;
}

const HafSangam: React.FC<any> = () => {
  const CLOSING_PANNA = ['000','100','110','111','112','113','114','115','116','117','118','119','120','122','123','124','125','126','127','128','129','130','133','134','135'
,'136','137','138','139','140','144','145','146','147','148','149','150','155','156','157','158','159','160','166','167','168','169','170','177','178'
,'179','180','188','189','190','199','200','220','222','223','224','225','226','227','228','229','230','233','234','235','236','237','238','239','240'
,'244','245','246','247','248','249','250','255','256','257','258','259','260','266','267','268','269','270','277','278','279','280','288','289','290'
,'299','300','330','333','334','335','336','337','338','339','340','344','345','346','347','348','349','350','355','356','357','358','359','360','366'
,'367','368','369','370','377','378','379','380','388','389','390','399','400','440','444','445','446','447','448','449','450','455','456','457','458'
,'459','460','466','467','468','469','470','477','478','479','480','488','489','490','499','500','550','555','556','557','558','559','560','566','567'
,'568','569','570','577','578','579','580','588','589','590','599','600','660','666','667','668','669','670','677','678','679','680','688','689','690'
,'699','700','770','777','778','779','780','788','789','790','799','800','880','888','889','890','899','900','990','999'];
  const digit = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const location = useLocation();
  const token = localStorage.getItem("token") || "";
  const [availablePoints, setAvailablePoints] = useState<string>("");
  const [remainingPoints, setRemainingPoints] = useState<string>("");
  const [miniBidAmount, setMinimumBidAmount] = useState<string>("");
  const [maxBidAmount, setMaxBidAmount] = useState<string>("");
  const [totalBidAmount, setTotalBidAmount] = useState(0);
    const [showList1, setShowList1] = useState(false);
    const [showList2, setShowList2]=useState(false);
    const [showList3, setShowList3]=useState(false);
    const [showList4, setShowList4]=useState(false);
    const [showBidList, setShowBidList] = useState(false);
  const [bids, setBids] = useState<Bid[]>([]);
  const [suggestedOpenPanna, setSuggestedOpenPanna] = useState<number[]>([]);
  const [suggestedClosePanna, setSuggestedClosePanna] = useState<number[]>([]);
  const [suggestedOpenDigit, setSuggestedOpenDigit] = useState<number[]>([]);
  const [suggestedCloseDigit, setSuggestedCloseDigit] = useState<number[]>([]);

  const { register, handleSubmit, formState: { errors }, reset,trigger, setValue } = useForm<Bid>();
  const [session, setSession] = useState<'open' | 'close'>('open'); // Track session state

  const onSubmit = (data: Bid) => {
    setShowBidList(true)
    const defaultValues: Partial<Bid> = {
      game_id: location?.state?.id,
      game_type: "half_sangam"
    };



    const bidData = { ...defaultValues, ...data, session }; // Ensure session is included
    setBids([...bids, bidData]);
    reset();
  };

  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };
  useEffect(() => {
    const total = bids.reduce((sum, bid) => sum + parseInt(bid.bid_points), 0);
    setTotalBidAmount(total);
    const remaining = parseInt(availablePoints)-total
    setRemainingPoints(remaining.toString())
  }, [bids]);
  const submitBid = async () => {
    if (bids.length === 0) {
      toast.error("Please add bids before submitting.");
      return;
    }

    const formData = new FormData();
    const modifiedBids = bids.map(bid => ({
      ...bid,
      open_panna: bid.open_panna || '',
      close_digit: bid.close_digit || '',
      open_digit: bid.open_digit || '',
      close_panna: bid.close_panna || ''
    }));

    formData.append("game_bids", JSON.stringify({ bids: modifiedBids }));

    try {
      const response = await axios.post(
        `${config.baseUrl}${config.placeBidEndpoint}`,
        formData,
        {
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
                setShowBidList(false)
                setBids([]); // Clear bids after successful submission
      } else {
        toast.error(response.data.message || "Failed to submit bids.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting bids. Please try again.");
    }
  };


  // const suggestPannas = (inputValue: string, type: string) => {

  //   if (type === 'open_digit') {
  //     const filteredPannas = digit.filter(panna => panna.toString().startsWith(inputValue));
  //     setSuggestedOpenDigit(filteredPannas);
  //   } else if (type === 'close_panna') {
  //     const filteredPannas = CLOSING_PANNA.filter(panna => panna.toString().startsWith(inputValue));
  //     setSuggestedClosePanna(filteredPannas);
  //   } else if (type === 'open_panna') {
  //     const filteredPannas = CLOSING_PANNA.filter(panna => panna.toString().startsWith(inputValue));
  //     setSuggestedOpenPanna(filteredPannas);
  //   } else if (type === 'close_digit') {
  //     const filteredPannas = digit.filter(panna => panna.toString().startsWith(inputValue));
  //     setSuggestedCloseDigit(filteredPannas);
  //   }
  // };


  type PannaType = "game_id" | "game_type" | "open_digit" | "close_panna" | "bid_points" | "session" | "open_panna" | "close_digit";

  const setOpenPanna = (panna: number, type: PannaType) => {
    setValue(type, panna.toString());
    if (type === 'open_digit') {
      setSuggestedOpenDigit([]);
    } else if (type === 'close_panna') {
      setSuggestedClosePanna([]);
    } else if (type === 'open_panna') {
      setSuggestedOpenPanna([]);
    } else {
      setSuggestedCloseDigit([]);
    }
  };



    // Custom validation function to check if open panna exists
    const validateOpenPanna = (value: string) => {
      const isValid = CLOSING_PANNA.includes(value);
      return isValid || "Enter valid panna.";
    };

    
  const fetchUserStatus = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      
      if (data?.status == "success") {
        setAvailablePoints(data?.data.available_points)
        setRemainingPoints(data?.data.available_points)
        setMaxBidAmount(data?.data.maximum_bid_amount)
        setMinimumBidAmount(data?.data.minimum_bid_amount)
      } else {
        toast.error(data?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching withdraw statement:", error);
      toast.error("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchUserStatus();
  }, []);
  const removeBid = (indexToRemove: number) => {
    // Get the bid points before updating state
    const removedBidPoints = parseInt(bids[indexToRemove].bid_points);
  
    // Update the bids list
    setBids((prevBids) => prevBids.filter((_, index) => index !== indexToRemove));
  
    // Update remaining points safely
    setRemainingPoints((prevRemaining) => (parseInt(prevRemaining) + removedBidPoints).toString());
  
    // Hide bid list if it's the last item being removed
    if (bids.length === 1) {
      setShowBidList(false);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> ,type: PannaType) => {
      let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
      setValue(type, value.toString(),{ shouldValidate: true });
      
      trigger(type); // Trigger validation
    };
  return (
    <div className="bg-gray-100 rounded-lg">
      <ToastContainer />
      <NavBar2 isHalfSangam={location?.state?.isHalfSangam} />
      {/* Total Balance Card */}
      <div style={{
        border: "1px solid black",
        borderRadius: "8px",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
        width: "90%",
        maxWidth: "400px",
        margin: "20px auto",
      }}
      className="custom-color text-white shadow-md">
          <div className="flex items-center justify-around mb-2">
            <p className="text-lg font-semibold">Total Balance</p>
          </div>
          {isNaN(parseInt(remainingPoints))?(
            <p>Loading...</p>
          ):(<p className="text-2xl font-semibold text-white-800">
            ₹ {remainingPoints}
          </p>)}
        </div>
      <div className="container shadow-md w-100 bg-white p-3 rounded-md text-left">
        <div className="flex">
          <MyDatePicker />
        </div>

        {/* Session Selection */}
        <div className="container text-left">
          <h2 className="choose_session font-bold mb-4">Choose Session</h2>
          <div className="open flex justify-between">
            <label>
              <input
                type="radio"
                checked={session === 'open'}
                onChange={() => setSession('open')}
              /> Open
            </label>
            <label>
              <input
                type="radio"
                checked={session === 'close'}
                onChange={() => setSession('close')}
              /> Close
            </label>
          </div>
          {errors.session && <span className="text-red-500">Please select a session</span>}
        </div>

        {/* Render form based on session */}
        {session === 'open' && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Open Digit</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>
                <input
                  type="tel"
                  id="open_digit"
                  {...register("open_digit", {
                    required: "Please enter open digit",
           validate: value => digit.includes(Number(value)) || "Only open digit allowed"
                  })}
                  autoComplete="off"
                inputMode="numeric"
                onFocus={() => setShowList1(true)}
          onBlur={() => setTimeout(() => setShowList1(false), 200)}
          list="digitSuggestions1"
                // onChange={(e) => suggestPannas(e.target.value)} 
                onInput={(e)=>handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "open_digit")}
          onChange={(e)=>handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "open_digit")}
                 
                  maxLength={1}
                  placeholder="Enter open digit"
                  className="form-control"
                />
               <datalist id="digitSuggestions1">
        {showList1 &&
          digit.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
            </div>
            {errors.open_digit && <span className="text-red-500">{errors.open_digit.message}</span>}
           
            </div>
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Close Panna</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>

                <input
                  type="tel"
                  id="close_panna"
                  className="form-control"
                  {...register("close_panna", {
                    required: "Please enter close Panna",
           validate: value => CLOSING_PANNA.includes(value) || "Only close panna allowed"
                  })}
                  autoComplete="off"
                inputMode="numeric"
                onFocus={() => setShowList2(true)}
          onBlur={() => setTimeout(() => setShowList2(false), 200)}
          list="digitSuggestions2"
                // onChange={(e) => suggestPannas(e.target.value)} 
                onInput={(e)=>handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "close_panna")}
          onChange={(e)=>handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "close_panna")}
                
                  placeholder="Enter close panna"
                />
               <datalist id="digitSuggestions2">
        {showList2 &&
          CLOSING_PANNA.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
            </div>
            {errors.close_panna && <span className="text-red-500">{errors.close_panna.message}</span>}
           
            </div>
            <div className="panna-new text-left mt-4">
                      <label htmlFor="points">Points</label>
                      <div className="input-group">
                        <span className="input-group-text"><FaRupeeSign /></span>
                        <input type="text" className="form-control"
                         {...register("bid_points", 
                         { required: "Please Enter Points",
                          pattern: /^[0-9]*$/,
                          validate: (value) => {
                            const bidPoints = parseInt(value);
                            const total = bidPoints+totalBidAmount;
                            
                            if (isNaN(bidPoints)) return "Please enter valid points";
                            if (total > parseInt(availablePoints))
                              return `Insufficient points`;
                            if (bidPoints < parseInt(miniBidAmount))
                              return `Minimum bid points is ${miniBidAmount}`;
                            if (bidPoints > parseInt(maxBidAmount))
                              return `Maximum bid points is ${maxBidAmount}`;
                            return true;
                          },
                         })}
                          placeholder="Enter Points"
                          maxLength={8}
                          onInput={(e) => {
                            const target = e.target as HTMLInputElement; // Type casting
                    target.value = target.value.replace(/[^0-9]/g, ""); // Restrict to numbers
                          }}
                          
                          />
                          
                      </div>
                      {errors.bid_points && <span className="text-red-500">{errors.bid_points.message}</span>}
                    </div>
            <div className="Proceed mt-6">
              <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
            </div>
          </form>
        )}

        {/* Form for 'close' session */}
        {session === 'close' && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Close Digit</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>
                <input
                  type="tel"
                  id="close_digit"
                  className="form-control"
                  {...register("close_digit", {
                    required: "Please enter close digit",
           validate: value => digit.includes(Number(value)) || "Only close digit allowed"
                  })}
                  autoComplete="off"
                inputMode="numeric"
                onFocus={() => setShowList3(true)}
          onBlur={() => setTimeout(() => setShowList3(false), 200)}
          list="digitSuggestions3"
                // onChange={(e) => suggestPannas(e.target.value)} 
                onInput={(e)=>handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "close_digit")}
          onChange={(e)=>handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "close_digit")}
                 
                  maxLength={1}
                  placeholder="Enter close digit"
                />
              <datalist id="digitSuggestions3">
        {showList3 &&
          digit.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
            </div>
            {errors.close_digit && <span className="text-red-500">{errors.close_digit.message}</span>}
           
            </div>
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Open Panna</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>
                <input
                  type="tel"
                  className="form-control"

                  maxLength={3}
                  minLength={3}

                  id="open_panna"

                  {...register("open_panna",{
                    required: "Please enter open Panna",
           validate: value => CLOSING_PANNA.includes(value as string) || "Only open panna allowed"
                  })}
                  autoComplete="off"
                inputMode="numeric"
                onFocus={() => setShowList4(true)}
          onBlur={() => setTimeout(() => setShowList4(false), 200)}
          list="digitSuggestions4"
                // onChange={(e) => suggestPannas(e.target.value)} 
                onInput={(e)=>handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "open_panna")}
          onChange={(e)=>handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "open_panna")}
                
                  placeholder="Enter Open Panna"
                /> 
                <datalist id="digitSuggestions4">
                {showList4 &&
                  CLOSING_PANNA.map((digit, index) => (
                    <option key={index} value={digit}>
                      {digit}
                    </option>
                  ))}
              </datalist>
                    </div>
                    {errors.open_panna && <span className="text-red-500">{errors.open_panna.message}</span>}
                   
            </div>
            <div className="panna-new text-left mt-4">
                      <label htmlFor="points">Points</label>
                      <div className="input-group">
                        <span className="input-group-text"><FaRupeeSign /></span>
                        <input type="text" className="form-control"
                         {...register("bid_points", 
                         { required: "Please Enter Points",
                          pattern: /^[0-9]*$/,
                          validate: (value) => {
                            const bidPoints = parseInt(value);
                            const total = bidPoints+totalBidAmount;
                            
                            if (isNaN(bidPoints)) return "Please enter valid points";
                            if (total > parseInt(availablePoints))
                              return `Insufficient points`;
                            if (bidPoints < parseInt(miniBidAmount))
                              return `Minimum bid points is ${miniBidAmount}`;
                            if (bidPoints > parseInt(maxBidAmount))
                              return `Maximum bid points is ${maxBidAmount}`;
                            return true;
                          },
                         })}
                          placeholder="Enter Points"
                          maxLength={8}
                          onInput={(e) => {
                            const target = e.target as HTMLInputElement; // Type casting
                    target.value = target.value.replace(/[^0-9]/g, ""); // Restrict to numbers
                          }}
                          
                          />
                          
                      </div>
                      {errors.bid_points && <span className="text-red-500">{errors.bid_points.message}</span>}
                    </div>
            <div className="Proceed mt-6">
              <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
            </div>
          </form>
        )}

{showBidList===true &&(
        <div  className="mt-6">
        <div className="flex justify-between items-center mb-4">
         <h2 className="font-bold">Bid List</h2>
         <h2 className="font-bold">Total Points: {totalBidAmount}/-</h2>
       </div>
        <table className="table">
          <thead>
            <tr >
              <th>Session</th>
              <th>Digit</th>
              <th>Panna</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {bids.map((bid, index) => (
              <tr key={index}>
                <td>{bid.session}</td>
                <td>{bid.open_digit || bid.close_digit}</td>
                  <td>{bid.close_panna || bid.open_panna}</td>
                <td>{bid.bid_points}</td>
                <td>
                     <button
                       className="btn btn-danger btn-sm"
                       onClick={() => removeBid(index)}
                     >
                       <FaTimes /> {/* Cross icon */}
                     </button>
                   </td>
              </tr>
            ))}
          </tbody>
        </table>
       </div>
       
       
               )}
               {showBidList===true&& (
                 <div className="mt-4">
        <button className="btn-submit bgmatch text-white py-2 w-100 px-4 rounded-lg" onClick={submitBid}>Proceed</button>
       </div>
               )} 
       
      </div>
    </div>
  );
};

export default HafSangam;
