import React, { useState } from 'react';
import Tesseract from 'tesseract.js';

interface ScreenshotUploaderProps {
  onExtract: (transactionId: string | null, screenshot: string | null,) => void; // Callback to pass extracted data
}

const ScreenshotUploader: React.FC<ScreenshotUploaderProps> = ({ onExtract }) => {
  const [image, setImage] = useState<string | null>(null); // Store the uploaded image
  const [extractedText, setExtractedText] = useState<string | null>(null); // Store the extracted text
  const [transactionId, setTransactionId] = useState<string | null>(null); // Store the extracted Transaction ID
  const [isLoading, setIsLoading] = useState(false); // Track OCR loading state

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string; // Convert the file to a Base64 string
        setImage(base64); // Set the image for display
        extractTextFromImage(base64); // Extract text from the image
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const extractTextFromImage = async (imageSrc: string) => {
    setIsLoading(true); // Start loading
    try {
      const result = await Tesseract.recognize(
        imageSrc, // Image source (Base64 or URL)
        'eng', // Language (English)
        {
          logger: (m) => console.log(m), // Log progress (optional)
        }
      );
      onExtract(null, imageSrc);
      findTransactionId(result.data.text,imageSrc); // Find the Transaction ID
      findUtr(result.data.text); // Find the UTR
      
    } catch (error) {
      console.error('Error extracting text:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const findTransactionId = (text: string,imageSrc: string)=> {
    // Regex to find Transaction ID or UPI transaction ID
    const transactionIdRegex = /(?:Transaction ID|UPI transaction ID)\s+([A-Z0-9]+)/;
    const match = text.match(transactionIdRegex);
    if(match!=null){
      setTransactionId(match[1])
      onExtract(match[1], imageSrc);
    }
    return match ? match[1] : null;
  };

  const findUtr = (text: string): string | null => {
    // Regex to find UTR
    const utrRegex = /UTR:\s+(\d+)/;
    const match = text.match(utrRegex);
    return match ? match[1] : null; // Return the UTR or null
  };


  const handleSaveText = () => {
    if (extractedText) {
      const blob = new Blob([extractedText], { type: 'text/plain;charset=utf-8' });
     
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Upload Screenshot</h1>
      <input
        type="file"
        accept="image/*" // Allow only image files
        onChange={handleImageUpload}
        style={{  marginTop:'10px',justifyContent: 'center', alignItems : 'center' , width:300 }}
      />

      {isLoading && <p>Extracting text...</p>}

      {image && (
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
          
          <img
            src={image}
            alt="Uploaded Screenshot"
            style={{  
              maxWidth: '100%', 
              height: 300, 
              marginBottom: '20px',
               justifyContent: 'center',
               scale: 'inherit',
                alignItems: 'center'}}
          />
        </div>
      )}
    </div>
  );
};

export default ScreenshotUploader;