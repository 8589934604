import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash, FaKey, FaLock, FaMobileAlt } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import logo from "../images/logo512.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import config from '../config';
import { useDispatch } from "react-redux";
import { isDisabled } from '@testing-library/user-event/dist/utils';

interface FormData {
  otp: string;
  password: string;
}

const VerifyOtp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState<number>(30); // Set timer to 30 seconds
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);


  // const [otp, setOtp] = React.useState('');
  const [showOtp, setShowOtp] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate()

  const { mobile, from } = location.state || {};

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (isResendDisabled && timer > 0) {
      setIsResendDisabled(true);
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false); // Enable resend button when timer ends
    }

    return () => clearInterval(countdown);
  }, [timer, isResendDisabled]);

  
  const handleVerifyOtp = async (data1: FormData) => {
    try {
      const formData = new URLSearchParams(); 
      formData.append("mobile", mobile);
      formData.append("otp", data1.otp);


      const response = await fetch( from=="signup"?`${config.baseUrl}${config.verifyUserEndpoint}`:`${config.baseUrl}${config.verifyOtpEndpoint}`, {
        method: "POST",
        body: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
        },
      });
      response.json().then((data: any) => {
        if(data.status=="success"){
          localStorage.setItem("token", data?.data?.token);
          if(from=="signup"){
            dispatch({
              type: "LOGIN_SUCCESS",
              payload: { token: data?.data?.token },
            });
            navigate('/home', { state: { from: true } });
          }else{
            handleCreate(data1,data?.data?.token)
          }
          
        }else{
          if(data?.message=="Invalid Data"){
            toast.error("Wrong Otp")
          }else{
            toast.error(data?.message)
          }
        }
      }).catch((error: any) => {
        toast.error(error)
      })
    } catch (error) {
      toast.error("Error during Otp send:")

    }
  };

  const handleCreate = async (data: FormData, token: any) => {
    try {
      const formData = new URLSearchParams(); 
      formData.append("mobile", mobile);
      formData.append(from=="password"?"password":"pin", data.password);


      const response = await fetch( from=="password"?`${config.baseUrl}${config.forgotPasswordVerifyEndpoint}`:`${config.baseUrl}${config.createPinEndpoint}`, {
        method: "POST",
        body: formData,
        headers: {
          token: token,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
        },
      });
      response.json().then((data: any) => {
        if(data.status=="success"){
          localStorage.setItem("token", data?.data?.token);
          if(from=="password"){
            navigate("/login" )
          }else{
            navigate("/security_pin" )
          }
        }else{
          toast.error(data?.message)
        }
      }).catch((error: any) => {
        toast.error(error)
      })
    } catch (error) {
   

    }
  };

  const handleResendOtp = async () => {  
    try {
      const mobileNum = mobile;
      const formData = new URLSearchParams();
      formData.append("mobile", mobileNum);

      const response = await fetch( `${config.baseUrl}${config.resendOtpEndpoint}`, {
        method: "POST",
        body: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
        },
      });
      response.json().then((data: any) => {
        setTimer(30);
        setIsResendDisabled(true);
        toast.success(data?.message)
      }).catch((error: any) => {
        toast.error(error)
      })
    } catch (error) {
      setIsResendDisabled(false);
      console.error("Error during otp send:", error);
      toast.error("Error during Otp send:")

    }
  };

  const handleToggleOtpVisibility = (typeOfInput: string) => {
    if (typeOfInput === "otp")
      setShowOtp(!showOtp);
    else
      setShowPassword(!showPassword)
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\s/g, '');
  };


  return (
    <div className="container ak mx-auto  p-4 max-w-md  shadow-md text-left login-primary text-white">
      <ToastContainer />
      <img src={logo} alt="Logo" className="flex mx-auto mb-4" />

      {/* Mobile number input */}
      <div className="mb-4 relative">
        <label htmlFor="mobile" className="block  text-left text-sm font-medium mb-1">
        
          Mobile Number
        </label>
        <FaMobileAlt className="absolute  top-9 left-4 text-black" />
        <input
          type="text"
          id="mobile"
          name="mobile"
          defaultValue={mobile}
          disabled
          className=" form-control w-full bg-white pl-30 px-5 py-2 border  focus:outline-none focus:border-blue-500 text-black"
        />
      </div>

      {/* OTP input */}
      <div className="mb-4 relative">
  <label htmlFor="otp" className="text-left mr-5 block text-sm font-medium mb-1">
    <FaKey className="absolute top-9 left-4 text-black" />
    OTP
  </label>
  <input
    type={showOtp ? 'tel' : 'password'}
    id="otp"
    placeholder="Enter Otp"
    inputMode="numeric" // Numeric keyboard on mobile
    required
    {...register("otp", { 
      required: "Enter Valid Otp",
      pattern: {
        value: /^[0-9]{4}$/, // Ensure only 4 numeric digits are entered
        message: "Enter Valid Otp",
      },
    })}
    className="form-control w-full px-5 py-2 pl-30  border  focus:outline-none focus:border-blue-500 text-black"
    maxLength={4} // Restrict input length to 4 characters at the DOM level
    onChange={(e) => {
      // Allow only numbers and limit to 4 digits
      e.target.value = e.target.value.replace(/\D/g, "").slice(0, 4);
    }}
  />
  <button 
    className="hideshow absolute top-9 right-4 text-black" 
    type="button" 
    onClick={() => handleToggleOtpVisibility("otp")}
  >
    {showOtp ? <FaEye /> : <FaEyeSlash />}
  </button>
  {errors.otp && <span className="text-red-500 text-sm">{ errors.otp.message}</span>}
      </div>
      {
        from!="signup"?(
          <div className="mb-4 relative">
        <label htmlFor="otp"  className="text-left mr-5 block text-sm font-medium mb-1">
          <FaLock className="absolute top-9 left-4 text-black" />
          {from=="password"?"Password":"Pin"}
        </label>
        <input
          type={showPassword ? 'text' : 'password'}
          id="password"
          placeholder={from=="password"?"Enter Password":"Enter Pin"}
          required
          {...register("password" , 
            {
              required: from=="password"?"Password is required":"Pin is required",
              minLength: {
                value: 4,
                message:from=="password"? "Password must be at least 4 characters":"Pin must be 4 digits",
              },
              maxLength: {
                value: 16,
                message: "Password cannot exceed 16 characters",
              },

            })}
          className=" form-control w-full px-5 py-2 pl-30 border  focus:outline-none focus:border-blue-500 text-black"
          maxLength={from=="password"?16:4}
          onChange={handleChange}
        />
        <button className="hideshow absolute top-9 right-4 text-black" type="button" onClick={() => handleToggleOtpVisibility("password")}>
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </button>
        {errors.password && <span className="text-red-500">{errors.password.message}</span>}

      </div>
        ):""
      }
      <div className="mt-3">
        {isResendDisabled ? ( 
          <p className="text-gray-400 text-sm">Resend OTP in {timer}s</p>
        ) : (
          <button
            onClick={handleResendOtp}
            className="text-blue-500 font-medium hover:underline"
          >
            Resend OTP
          </button>
        )}
      </div>
      

      {/* Verify OTP button */}
      <button
        type="button"
        onClick={handleSubmit(handleVerifyOtp)}
        className="w-full mt-4 bg-blue-300 text-black py-2 mb-2 rounded-5 hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
      >
        Verify
      </button>
    </div>
  );
};

export default VerifyOtp;
