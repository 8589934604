import React, { useEffect, useState } from "react";
import { NavBar2 } from "../NavBar2";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import config from '../../config'; 

interface WinHistoryProps {
  data: {
    bid_points: string;
    bidded_at: string;
    digit: string;
    game_id: string;
    game_name: string;
    game_type: string;
    panna: string;
    win_points: string;
    won_at: string;
    left_digit:string;
    right_digit:string;
    jodi_digit:string
  }[];
}

export const DisawerWinHistory: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const todayDate = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState<string>(todayDate);
  const [toDate, setToDate] = useState<string>(todayDate);
  const [Data, setData] = useState<WinHistoryProps["data"]>([]);

  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(event.target.value);
  };



 const handleSubmit = async () => {
    console.log("From Date:", fromDate);
    console.log("To Date:", toDate);
  
    try {
      // Set the start of the day (00:00) for fromDate
      const fromDateWithTime = new Date(fromDate);
      fromDateWithTime.setHours(0, 0, 0, 0);
      
      // Set the end of the day (23:59) for toDate
      const toDateWithTime = new Date(toDate);
      toDateWithTime.setHours(23, 59, 59, 999);
  
      const formData = new URLSearchParams();
      formData.append("from_date", fromDateWithTime.toISOString());
      formData.append("to_date", toDateWithTime.toISOString());
  
      let apiUrl = `${config.baseUrl}${config.galiDisawarWinHistoryEndpoint}`;
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          token,
          "Content-Type": "application/x-www-form-urlencoded",
          Cookie: "ci_session=d2a8bfc834befa449f25ec1a4d1e4de08c515354",
        },
        body: formData,
      });
  
      response
        .json()
        .then((data: any) => {
          if (data?.code === "100") {
            setData(data.data);
            console.log(data.data);
          } else {
            toast.error(data?.message);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred");
        });
    } catch (error) {
      console.error("Error during login:", error);
    }
  };


  useEffect(() => {
    handleSubmit();
  }, []);
  function snakeCaseToHumanReadable(str: string): string {
  
    return str
      .split('_') // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words with spaces
  }
  return (
    <>
      <NavBar2 isGaliDesawarWinHistory={true} />
      <div className="container mx-auto p-2 rounded-lg text-white mt-4">
        <ToastContainer />
        <div className="login-primary px-4 py-4 rounded-4">
          <div className="flex justify-between">
            <h1 className="text-white">From Date</h1>
            <h1 className="text-white">To Date</h1>
          </div>

          <div className="flex justify-between">
            <input
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              className="rounded-lg  py-2 mt-2 pl-2 mb-4 bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400 ml-2"
            />

            <input
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              max={todayDate}
              className="rounded-lg  py-2 mt-2 pl-2 mb-4 bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400 ml-2"
            />
          </div>

          <button
            onClick={handleSubmit}
            className="rounded mt-3 bg-blue-300 px-5 py-2 text-black"
          >
            Submit
          </button>
        </div>
      </div>

      <div className=" mt-4">
      {Data?.length > 0 ? (
  Data.map((item, index) => (
    <div key={index}>
      <div
        className="card mb-3"
        style={{
          border: "1px solid #d1d9e6",
          backgroundColor: "#f7faff",
          borderRadius: "8px",
          maxWidth: "96%",
          margin: "auto",
        }}
      >
        <div className="card-body p-3">
          {/* Game time and type */}
          <h6
            className="mt-1"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "start",
            }}
          >
            {item.game_name} ({snakeCaseToHumanReadable(item.game_type)})
          </h6>

          {/* Points won */}
          <span
            style={{
              color: "green",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            Won Points: {item.win_points}
          </span>

          {/* Bid points and digit/panna */}
          <div className="d-flex justify-content-between mt-2">
            <span style={{ fontSize: "14px" }}>
              <strong>Bid Points:</strong> {item.bid_points}
            </span>
            {item.game_type==="left_digit" && (
                      <p
                        className="mb-1"
                        style={{ fontSize: "14px", textAlign: "start" }}
                      >
                        <strong>Digit :</strong> {item.left_digit}
                      </p>
                    ) }

                    {item.game_type==="right_digit" && (
                      <p
                        className="mb-1"
                        style={{ fontSize: "14px", textAlign: "start" }}
                      >
                        <strong>Digit :</strong> {item.right_digit || "N/A"}
                      </p>
                    )}
                    {item.game_type==="jodi_digit"  && (
                       <p
                       className="mb-1"
                       style={{ fontSize: "14px", textAlign: "start" }}
                     >
                       <strong>Digit :</strong> {item.left_digit }{ item.right_digit }
                     </p>
                    )}
          </div>

          {/* Date and time */}
          <p
            className="mb-0"
            style={{
              fontSize: "12px",
              color: "#6c757d",
              textAlign: "start",
              marginTop: "10px",
            }}
          >
            {new Date(item.bidded_at).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  ))
) : (
  <div className="text-center mt-4">
              <img
                src="https://img.freepik.com/premium-vector/no-data-concept-illustration_634196-28497.jpg?semt=ais_hybrid" // Replace with your image path
                alt="No Data"
                style={{ width: "150px", height: "150px", margin: "auto" }}
              />
              <p style={{ color: "#6c757d", marginTop: "10px" }}>No data available</p>
            </div>
          )}
      </div>
    </>
  );
};

export default DisawerWinHistory;
