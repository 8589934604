// import React from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
// } from "react-router-dom";
// import logo from "./logo.svg";
// import "./App.css";
// // import Navbar from "./Components/NavBar";
// import SignUpForm from "./Components/SignUp";
// import Login from "./Components/Login";
// import Carousel from "./Components/carousel";
// import Home from "./Components/Home";
// import WithoutNavbar from "./Components/WithoutNavbar";
// import WithNavbar from "./Components/WithNavbar";
// import ForgotPassword from "./Components/ForgotPassword";
// import StarLine from "./Components/StarLine";
// import { BidHistory } from "./Components/Shwet/BidHistory";
// import WinHistory from "./Components/Shwet/WinHistory";
// import { Funds } from "./Components/Funds";
// import { Help } from "./Components/Help";
// import SecurityPin from "./Components/SecurityPin";
// import { MadhurNight } from "./Components/MadhurNight";
// import GameTime from "./Components/GameTime";
// import EditProfile from "./Components/EditProfile";
// import Withdraw from "./Components/Withdraw";
// import { Verify } from "crypto";
// import VerifyOtp from "./Components/VerifyOtp";
// import ChangePassword from "./Components/ChangePassword";
// import DoublePanna from "./Components/DoublePanna";
// import GameRates from "./Components/GameRates";
// import ContactUs from "./Components/ContactUs";
// import Wallet from "./Components/Wallet";
// import { useSelector } from "react-redux";
// import SinglePanna from "./Components/SinglePanna";
// import SingleDigit from "./Components/singleDigit";
// import HalfSangam from "./Components/HalfSangam";
// import FullSangam from "./Components/FullSangam";
// import TripplePanna from "./Components/TripplePanna";
// import JodiPanna from "./Components/JodiPanna";
// import Transfer from "./Components/Transfer";
// import GpayDetails from "./Components/GpayDetails";
// import PhonePay from "./Components/PhonePay";
// import PayTmDetails from "./Components/PayTmDetails";
// import BankDetails from "./Components/BankDetails";
// import PaymentMethods from "./Components/PaymentMethods";
// import BidHistoryDisawar from "./Components/Shwet/BidHistoryDisawar";
// import BidHistorystar from "./Components/Shwet/BidHistorystart";
// import StarlineGameplay from "./Components/StarlineGameplay";

// import BettingInterface from "./Components/Shwet/BetPage";
// import StartWinHistory from "./Components/Shwet/StartWinHistory";
// import DisawerWinHistory from "./Components/Shwet/DisawarWinHistory";
// import DesawarGameplay from "./Components/Shwet/DisawarGameplay";

// function App() {
//   const token = localStorage.getItem("token");
//   const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

//   return (
//     <div className="App">
//       <Router>
//         {/* <Navbar /> */}
//         <Routes>
//           <Route element={<WithoutNavbar />}>
//             <Route path="/login" element={<Login />} />
//           </Route>
//           <Route element={<WithoutNavbar />}>
//             <Route path="/signup" element={<SignUpForm />} />
//           </Route>
//           <Route element={<WithoutNavbar />}>
//             <Route path="/security_pin" element={<SecurityPin />} />
//           </Route>

//           <Route element={<WithNavbar />}>
//             {/* <Route path="/" element={<Home />} /> */}
//             <Route
//               path="/"
//               element={isLoggedIn ? <Home /> : <Navigate to="/login" />}
//             />
//           </Route>

//           <Route element={<WithoutNavbar />}>
//             <Route path="/ForgotPassword" element={<ForgotPassword />} />
//           </Route>

//           <Route element={<WithoutNavbar />}>
//             <Route
//               path="/starline"
//               element={isLoggedIn ? <StarLine /> : <Navigate to="/login" />}
//             />
//           </Route>
//           <Route element={<WithoutNavbar />}>
//             <Route
//               path="/bidhistory"
//               element={isLoggedIn ? <BidHistory /> : <Navigate to="/login" />}
//             />
//           </Route>
//           <Route element={<WithoutNavbar />}>
//           <Route
//               path="/bidhistorystar"
//               element={isLoggedIn ? <BidHistorystar /> : <Navigate to="/login" />}
//             />
//           </Route>
          
//           <Route
//               path="/bidhistorydisawar"
//               element={isLoggedIn ? <BidHistoryDisawar /> : <Navigate to="/login" />}
//             />
          
//           <Route element={<WithoutNavbar />}>
//           <Route
//               path="/starlanplay/:id"
//               element={isLoggedIn ? <StarlineGameplay /> : <Navigate to="/login" />}
//             />
//             </Route>
//             <Route
//               path="/betpage"
//               element={isLoggedIn ? <BettingInterface /> : <Navigate to="/login" />}
//             />
         
//           <Route element={<WithoutNavbar />}>
//           <Route 
//               path="/desawerplay/:id"
//               element={isLoggedIn ? <DesawarGameplay/> : <Navigate to="/login" />}
//             />
//           </Route>
//           <Route element={<WithoutNavbar />}>
//             <Route
//               path="/winhistory"
//               element={isLoggedIn ? <WinHistory /> : <Navigate to="/login" />}
//             />
//           </Route>


//           <Route element={<WithoutNavbar />}>
//             <Route
//               path="/winhistorystar"
//               element={isLoggedIn ? <StartWinHistory /> : <Navigate to="/login" />}
//             />
//           </Route>
//           <Route element={<WithoutNavbar />}>
//             <Route
//               path="/winhistorydisawar"
//               element={isLoggedIn ? <DisawerWinHistory /> : <Navigate to="/login" />}
//             />
//           </Route>

//           <Route element={<WithoutNavbar />}>
//             <Route
//               path="/funds"
//               element={isLoggedIn ? <Funds /> : <Navigate to="/login" />}
//             />
//           </Route>

//           <Route element={<WithoutNavbar />}>
//             <Route
//               path="/help"
//               element={isLoggedIn ? <Help /> : <Navigate to="/login" />}
//             />
//           </Route>

//           <Route element={<MadhurNight />}>
//             <Route
//               path="/madhurnight"
//               element={isLoggedIn ? <MadhurNight /> : <Navigate to="/login" />}
//             />
//           </Route>
//           <Route element={<GameTime />}>
//             <Route
//               path="/gametime"
//               element={isLoggedIn ? <GameTime /> : <Navigate to="/login" />}
//             />
//           </Route>

//           <Route element={<EditProfile />}>
//             <Route
//               path="/editprofile"
//               element={isLoggedIn ? <EditProfile /> : <Navigate to="/login" />}
//             />
//           </Route>

//           <Route element={<Withdraw />}>
//             <Route
//               path="/withdraw"
//               element={isLoggedIn ? <Withdraw /> : <Navigate to="/login" />}
//             />
//           </Route>

//           <Route element={<VerifyOtp />}>
//             <Route
//               path="/VerifyOtp"
//               element={isLoggedIn ? <VerifyOtp /> : <Navigate to="/login" />}
//             />
//           </Route>

//           <Route element={<ChangePassword />}>
//             <Route
//               path="/ChangePassword"
//               element={
//                 isLoggedIn ? <ChangePassword /> : <Navigate to="/login" />
//               }
//             />
//           </Route>

//           <Route path="/DoublePanna" element={<DoublePanna />} />

//           <Route path="/JodiDigit" element={<JodiPanna />} />

//           <Route path="/SinglePanna" element={<SinglePanna />} />

//           <Route path="/TripplePanna" element={<TripplePanna />} />

//           <Route path="/SingleDigit" element={<SingleDigit />} />

//           <Route path="/HalfSangam" element={<HalfSangam />} />

//           <Route path="/FullSangam" element={<FullSangam />} />

//           <Route path="/transfer" element={<Transfer />} />
//           <Route path="/gpay" element={<GpayDetails />} />
//           <Route path="/phonepe" element={<PhonePay />} />
//           <Route path="paytm" element={<PayTmDetails />} />
//           <Route path="/bank-details" element={<BankDetails />} />
//           <Route path="/payment-methods" element={<PaymentMethods />} />

//           <Route
//             path="/GameRates"
//             element={isLoggedIn ? <GameRates /> : <Navigate to="/login" />}
//           />

//           <Route
//             path="/ContactUs"
//             element={isLoggedIn ? <ContactUs /> : <Navigate to="/login" />}
//           />

//           <Route
//             path="/Wallet"
//             element={isLoggedIn ? <Wallet /> : <Navigate to="/login" />}
//           />
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;




import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
// import Navbar from "./Components/NavBar";
import SignUpForm from './Components/SignUp';
import Login from './Components/Login';
import Carousel from './Components/carousel';
import Home from './Components/Home';
import WithoutNavbar from './Components/WithoutNavbar';
import WithNavbar from './Components/WithNavbar';
import ForgotPassword from './Components/ForgotPassword';
import StarLine from './Components/StarLine';
import { BidHistory } from './Components/Shwet/BidHistory';
import WinHistory from './Components/Shwet/WinHistory';
import { Funds } from './Components/Funds'; 
import { Help } from './Components/Help';
import SecurityPin from './Components/SecurityPin';
import { MadhurNight } from './Components/MadhurNight';
import GameTime from './Components/GameTime';
import EditProfile from './Components/EditProfile';
import Withdraw from './Components/Withdraw';
import VerifyOtp from './Components/VerifyOtp';
import ChangePassword from './Components/Shwet/ChangePassword';
import DoublePanna from './Components/DoublePanna';
import GameRates from './Components/GameRates';
import ContactUs from './Components/ContactUs';
import Wallet from './Components/Wallet';
import SinglePanna from './Components/SinglePanna';
import SingleDigit from './Components/singleDigit';
import HalfSangam from './Components/HalfSangam';
import FullSangam from './Components/FullSangam';
import TripplePanna from './Components/TripplePanna';
import JodiPanna from './Components/JodiPanna';
import Transfer from './Components/Transfer';
import GpayDetails from './Components/GpayDetails';
import PhonePay from './Components/PhonePay';
import PayTmDetails from './Components/PayTmDetails';
import BankDetails from './Components/BankDetails';
import PaymentMethods from './Components/PaymentMethods';
import BidHistoryDisawar from './Components/Shwet/BidHistoryDisawar';
import BidHistorystar from './Components/Shwet/BidHistorystart';
import StarlineGameplay from './Components/Shwet/StarlineGameplay';
import BettingInterface from './Components/Shwet/BetPage';
import StartWinHistory from './Components/Shwet/StartWinHistory';
import DisawerWinHistory from './Components/Shwet/DisawarWinHistory';
import DesawarGameplay from './Components/Shwet/DisawarGameplay';
import { useSelector } from 'react-redux';
import StarBettingInterface from './Components/Shwet/StarBetPage';
import { ToastContainer } from 'react-toastify';
import ForgotPin from './Components/Shwet/forgotpin';
import Verifypin from './Components/Shwet/Verifyotppin';
import Newypin from './Components/Shwet/Newpin';
import Chart from './Components/Shwet/Chart';
import FloatingButton from './Components/Shwet/suportbutton';
import Notice from './Components/Notice';
import { AddFund } from './Components/AddFund';
import PaymentOptions from './Components/PaymentOptions';

function App() {
  const token = localStorage.getItem('token');
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Routes without Navbar */}
          <Route element={<WithoutNavbar />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUpForm />} />
            <Route path="/security_pin" element={<SecurityPin />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ForgotPin" element={<ForgotPin />} />
            <Route path="/VerifyOtp" element={ <VerifyOtp /> } />
            <Route path="/Verifypin" element={ <Verifypin /> } />

            <Route path="/chart/:chartLink" element={<Chart />} />
            
            <Route path="/starline" element={isLoggedIn ? <StarLine /> : <Navigate to="/login" />} />
            <Route path="/paymentOptions" element={isLoggedIn ? <PaymentOptions /> : <Navigate to="/login" />} />
            <Route path="/bidhistory" element={isLoggedIn ? <BidHistory /> : <Navigate to="/login" />} />
            <Route path="/bidhistorystar" element={isLoggedIn ? <BidHistorystar /> : <Navigate to="/login" />} />
            <Route path="/bidhistorydisawar" element={isLoggedIn ? <BidHistoryDisawar /> : <Navigate to="/login" />} />
            <Route path="/winhistory" element={isLoggedIn ? <WinHistory /> : <Navigate to="/login" />} />
            <Route path="/winhistorystar" element={isLoggedIn ? <StartWinHistory /> : <Navigate to="/login" />} />
            <Route path="/winhistorydisawar" element={isLoggedIn ? <DisawerWinHistory /> : <Navigate to="/login" />} />
            <Route path="/funds" element={isLoggedIn ? <AddFund /> : <Navigate to="/login" />} />
            <Route path="/help" element={isLoggedIn ? <Help /> : <Navigate to="/login" />} />
            <Route path="/madhurnight" element={isLoggedIn ? <MadhurNight /> : <Navigate to="/login" />} />
            <Route path="/gametime" element={isLoggedIn ? <GameTime /> : <Navigate to="/login" />} />
            <Route path="/editprofile" element={isLoggedIn ? <EditProfile /> : <Navigate to="/login" />} />
            <Route path="/withdraw" element={isLoggedIn ? <Withdraw /> : <Navigate to="/login" />} />
            <Route path="/ChangePassword" element={isLoggedIn ? <ChangePassword /> : <Navigate to="/login" />} />
            <Route path="/DoublePanna" element={<DoublePanna />} />
            <Route path="/JodiDigit" element={<JodiPanna />} />
            <Route path="/SinglePanna" element={<SinglePanna />} />
            <Route path="/TripplePanna" element={<TripplePanna />} />
            <Route path="/SingleDigit" element={<SingleDigit />} />
            <Route path="/HalfSangam" element={<HalfSangam />} />
            <Route path="/FullSangam" element={<FullSangam />} />
            <Route path="/transfer" element={<Transfer />} />
            <Route path="/gpay" element={<GpayDetails />} />
            <Route path="/newpin" element={<Newypin />} />
            <Route path="/phonepe" element={<PhonePay />} />
            <Route path="/paytm" element={<PayTmDetails />} />
            <Route path="/bank-details" element={<BankDetails />} />
            <Route path="/payment-methods" element={<PaymentMethods />} />
            <Route path="/GameRates" element={isLoggedIn ? <GameRates /> : <Navigate to="/login" />} />
            <Route path="/Notice" element={isLoggedIn ? <Notice /> : <Navigate to="/login" />} />
            <Route path="/ContactUs" element={isLoggedIn ? <ContactUs /> : <Navigate to="/login" />} />
            <Route path="/Wallet" element={isLoggedIn ? <Wallet /> : <Navigate to="/login" />} />
            <Route path="/starlanplay/:id/:name" element={isLoggedIn ? <StarlineGameplay /> : <Navigate to="/login" />} />
            <Route path="/betpage" element={isLoggedIn ? <BettingInterface /> : <Navigate to="/login" />} />
            <Route path="/starbetpage" element={isLoggedIn ? <StarBettingInterface /> : <Navigate to="/login" />} />

            <Route path="/desawerplay/:id/:name" element={isLoggedIn ? <DesawarGameplay /> : <Navigate to="/login" />} />
          </Route>

          {/* Routes with Navbar */}
          <Route element={<WithoutNavbar />}>
            <Route path="/" element={isLoggedIn ? <Navigate to="/security_pin" /> : <Navigate to="/signup" />} />
           
          </Route>
          {/* Routes with navbar */}
          <Route element={<WithNavbar/>}>
          <Route path="/home" element={<Home />} />
          </Route>
        </Routes>
        <FloatingButton/>
      </Router>
      <ToastContainer/>
    </div>
  );
}

export default App;
