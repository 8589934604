import { useEffect, useState } from "react";
import { FaPhoneAlt, FaTelegram, FaWhatsapp, FaEnvelope, FaWallet } from "react-icons/fa";
import { NavBar2 } from "./NavBar2"; 
import { Link } from "react-router-dom";
import config from '../config';

interface ContactDetails {
  whatsapp_no: string;
  mobile_no_1: string;
  mobile_no_2: string;
  email_1: string;
  telegram_no: string;
  withdraw_proof: string;
}

const ContactUs = () => {
  const [contactDetails, setContactDetails] = useState<ContactDetails | null>(
    null
  );
  const [accountStatus, setAccountStatus] = useState<string>("");
  const token = localStorage.getItem("token") || '';

  useEffect(() => {
    walletData();
  }, []);
  const walletData = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab1  5b1746f67a94c05d0d6761be9f3",
          },
        }
      );
      response
        .json()
        .then((data: any) => {
          if(data?.status=="success"){
            setAccountStatus(data?.data?.account_status)
          }
          
        })
        .catch((error: any) => {
          // console.log({ error }) ;
          // alert(error)
        });
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };
  useEffect(() => {
    appData();
  }, []);
  const appData = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.appDetailsEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab1  5b1746f67a94c05d0d6761be9f3",
          },
        }
      );
      response
        .json()
        .then((data: any) => {
          if(data?.status=="success"){
            setContactDetails(data?.data?.contact_details)
          }
          
        })
        .catch((error: any) => {
          // console.log({ error }) ;
          // alert(error)
        });
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };
  
  return (
    <div className=" bg-white">
      <NavBar2 isContactUs={true} />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4">
          {/* Mobile Number */}
          <div
            className="flex items-center bg-white p-4 rounded-lg shadow-md justify-center cursor-pointer"
            onClick={() => {
              if (contactDetails) {
                window.location.href = `tel:${contactDetails.mobile_no_1}`;
              }
            }}
          >
            
            <div>
              <div className="flex flex-row justify-center">
              <FaPhoneAlt className="text-blue-500 mr-2 mt-1" />
              <p className="text-lg font-semibold">Mobile Number</p>
              </div>
              
              {(contactDetails?.mobile_no_1)? <p className="text-gray-600">+91 {contactDetails?.mobile_no_1}</p>:<p>Loading...</p>}
             
            </div>
          </div>

          {/* Telegram */}
          <div className="flex items-center bg-white p-4 rounded-lg shadow-md justify-center cursor-pointer">
            
            <div>
            <div className="flex flex-row justify-center">
              <FaTelegram className="text-blue-500 mr-2 mt-1" />
              <p className="text-lg font-semibold">Telegram</p>
              </div>
              
              <a
                href={`${contactDetails?.telegram_no}`}
                className="text-gray-600"
                target="_blank"
                rel="noopener noreferrer"
              >
                {(contactDetails?.telegram_no)? <p className="text-gray-600">{contactDetails?.telegram_no}</p>:<p>Loading...</p>}
               
              </a>
            </div>
          </div>

          {/* WhatsApp */}
          <div
            className="flex items-center bg-white p-4 rounded-lg shadow-md justify-center cursor-pointer"
            onClick={() => {
              if (contactDetails) {
                window.location.href = `https://wa.me/+${contactDetails.whatsapp_no}`;
              }
            }}
          >
            <div>
            <div className="flex flex-row justify-center">
              <FaWhatsapp className="text-blue-500 mr-2 mt-1" />
              <p className="text-lg font-semibold">WhatsApp</p>
              </div>
              
              {(contactDetails?.whatsapp_no)? <p className="text-gray-600">+91 {contactDetails?.whatsapp_no}</p>:<p>Loading...</p>}
              
            </div>
          </div>

          {/* Email */}
          <div className="flex items-center bg-white p-4 rounded-lg shadow-md justify-center cursor-pointer">
            
            <div>
              <div className="flex flex-row justify-center">
              <FaEnvelope className="text-blue-500 mr-2 mt-1" />
              <p className="text-lg font-semibold">Email</p>
              </div>
              {contactDetails?.email_1 ? (
                <Link
                  to={`mailto:${contactDetails.email_1}`} // direct anchor link
                  className="text-gray-600"
                >
                  {(contactDetails?.email_1)? <p className="text-gray-600">{contactDetails?.email_1}</p>:<p>Loading...</p>}
                </Link>
              ) : (
                <span className="text-gray-400">Loading...</span>
              )}
            </div>
          </div>

          {/* Withdraw Proov */}
          {accountStatus=="1" && (
            <div className="flex items-center bg-white p-4 rounded-lg shadow-md justify-center cursor-pointer">
           
            <div>
              <div className="flex flex-row justify-center">
              <FaWallet className="text-blue-500 mr-2 mt-1" />
              <p className="text-lg font-semibold">Withdraw Proof</p>
              </div>
              {contactDetails?.withdraw_proof ? (
                <Link
                  to={`https://wa.me/${contactDetails.withdraw_proof}`} // direct anchor link
                  className="text-gray-600"
                >
                  {contactDetails.withdraw_proof}
                </Link>
              ) : (
                <span className="text-gray-400">Loading...</span>
              )}
            </div> 
          </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
