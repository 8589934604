const config = {
  appName: "kalyan Rocy Matka",
    baseUrl: "https://development.smapidev.co.in/api/Api",
    version: "Main",
  loginEndpoint: "/login",
  signupEndpoint: "/signup",
  loginPinEndpoint: "/login_pin",
  forgotPasswordEndpoint: "/forgot_password",
  forgotPinEndpoint: "/forgot_pin",
  createPinEndpoint: "/create_pin",
  forgotPasswordVerifyEndpoint: "/forgot_password_verify",
  verifyUserEndpoint: "/verify_user",
  verifyOtpEndpoint: "/verify_otp",
  userStatusEndpoint: "/user_status",
  resendOtpEndpoint: "/resend_otp",
  updatePhonePeEndpoint: "/update_phonepe",
  updateGpayEndpoint: "/update_gpay",
  updatePaytmEndpoint: "/update_paytm",
  updateProfileEndpoint: "/update_profile",
  updateBankDetailsEndpoint: "/update_bank_details",
  userDetailsEndpoint: "/get_user_details",
  appDetailsEndpoint: "/app_details",
  addfundEndpoint: "/add_fund",
  transferPointsEndpoint: "/transfer_points",
  transferVerifyEndpoint: "/transfer_verify",
  howToPlayEndpoint: "/how_to_play",
  mainGameListEndpoint: "/main_game_list",
  placeBidEndpoint: "/place_bid",
  gameRateListEndpoint: "/game_rate_list",
  withdrawEndpoint: "/withdraw",
  winHistoryEndpoint: "/win_history",
  bidHistoryEndpoint: "/bid_history",
  walletStatementEndpoint: "/wallet_statement",
  withdrawStatementEndpoint: "/withdraw_statement",
  starlineGameEndpoint: "/starline_game",
  starlinePlaceBidEndpoint: "/starline_place_bid",
  starlineBidHistoryEndpoint: "/starline_bid_history",
  starlineWinHistoryEndpoint: "/starline_win_history",
  galiDisawarGameEndpoint: "/gali_disawar_game",
  galiDisawarPlaceBidEndpoint: "/gali_disawar_place_bid",
  galiDisawarBidHistoryEndpoint: "/gali_disawar_bid_history",
  galiDisawarWinHistoryEndpoint: "/gali_disawar_win_history",
  readNotificationEndpoint: "/read_notification",
  paymentConfigEndpoint: "/payment_config",
  paymentReceiveEndpoint: "/payment_receive",
  paymentRequestEndpoint: "/payment_request",
};

export default config;
