// Carousel.tsx
import React, { useEffect, useRef, useState } from "react";
import Carousel from "./carousel";
import {
  FaChartBar,
  FaChartLine,
  FaGamepad,
  FaGlobe,
  FaHandHoldingUsd,
  FaMoneyBillWave,
  FaPhone,
  FaPlay,
  FaPlayCircle,
  FaSpinner,
  FaStreetView,
  FaWhatsapp,
} from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import a from "../images/a.png"
import b from "../images/b.png"
import c from "../images/c.png"
import config from '../config';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "react-toastify/dist/ReactToastify.css"
import { Button } from "@mui/material";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token") || '';

  const [mainGameList, setMainGameList] = useState([]);
  const [WhatsAppno, serWhatsappno] = useState()
  const [phone, serPhone] = useState()
  const [playLink,serPlayLink] = useState()
  const [accountStatus, setAccountStatus] = useState<string>("");
  const [welcomeMessage, setWelcomeMessage] = useState<string>("");
  const [galidesawarStatus, setGalidesawarStatus] = useState<string>("");
  const [starlineStatus, setStarlineStatus] = useState<string>("");
  const [bannerStatus, setBannerStatus] = useState<string>("");
  const [marqueeStatus, setMarqueeStatus] = useState<string>("");
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false)
  const popupRef = useRef<HTMLDivElement>(null); // Ref for the popup content
  const [vipStatus, setVipStatus] = useState<Boolean>(false);


  useEffect(() => {
    fetchAppDetails()
  }, [])
  useEffect(() => {
    // Add event listener to detect clicks outside the popup
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        closePopup();
      }
    };

    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);
  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    gameList()
  }, [])

  useEffect(() => {
    walletData();
  }, []);

  const walletData = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab1  5b1746f67a94c05d0d6761be9f3",
          },
        }
      );
      response
        .json()
        .then((data: any) => {
          if(data?.status=="success"){
            setAccountStatus(data?.data?.account_status)
            setVipStatus(data?.data?.vip_status);
            if(data?.data?.account_status=="1"){
              if (location.state?.from) {
                setShowPopup(true);
                window.history.replaceState({}, '');
              }
            }
          }
          
        })
        .catch((error: any) => {
          // console.log({ error }) ;
          // alert(error)
        });
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };
  const gameList = async () => {
    try {
      setLoading(true)
      const response = await fetch( `${config.baseUrl}${config.mainGameListEndpoint}`,
        {
          method: "POST",
          headers: {
            token
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
          },
        });

      response.json().then((result: any) => {
        if (result.code == 100) {
          setMainGameList(result?.data)
        }
        setLoading(false)
      }).catch((error: any) => {
        alert(error)
        setLoading(false)

      })

    } catch (error) {
      toast.error("Internal server error ")
      // alert("ERROR IN GAME LIST" + error)
    }
  }

  const fetchAppDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch( `${config.baseUrl}${config.appDetailsEndpoint}`,
        {
          method: "POST",
          headers: {
            token
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
          },
        });

      response.json().then((result: any) => {
        if (result.code == 100) {
          serWhatsappno(result.data.contact_details.whatsapp_no)
          serPhone(result.data.contact_details.mobile_no_1)
          serPlayLink(result.data.play_link)
          setWelcomeMessage(result.data.welcome_message)
          setGalidesawarStatus(result.data.project_status.galidesawar_market)
          setStarlineStatus(result.data.project_status.starline_market)
          setBannerStatus(result.data.project_status.banner_status)
          setMarqueeStatus(result.data.marquee_status)
          console.log("appDetails"+result.data)
        }
        setLoading(false)
      }).catch((error: any) => {
        alert(error)
        setLoading(false)

      })

    } catch (error) {
      toast.error("Internal server error ")
      // alert("ERROR IN GAME LIST" + error)
    }
  }





  return (
    // <>
    <div>
      {welcomeMessage &&(
        <Dialog
        open={showPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >Welcome</DialogTitle>
        <DialogContent>
          <p>
            {welcomeMessage}
          </p>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setShowPopup(false)}
            className=" !text-white bg-danger"
          >
            Dismiss
          </Button>
        
        </DialogActions>
      </Dialog>
      )}
       {bannerStatus=="On"&&(
        <Carousel />
       )}
      
      <div className="container primary-text">
        <div className="flex flex-row gap-3">

          {/* Phone Button */}
          <button 
            onClick={() => {
              window.open(`tel:${phone}`, '_blank');
            }}
            className="bg-white hover:bg-black-600 text-black font-medium py-2 px-2 rounded"
          > 
            <FaPhone className="fs-6" />
            <span>Call Us</span>
           

          </button>

          {/* WhatsApp Button */}
          <button
            className="custom-green text-white font-medium py-2 px-2 rounded"
            onClick={() => {
              window.location.href = `https://wa.me/+91${WhatsAppno}`;
            }}
          >
            <FaWhatsapp className="fs-6" />
            WhatsApp
          </button>

          {/* How to Play Button */}

          {accountStatus!="2"&&(
            <button className="custom-purple text-white font-medium py-2 px-2 rounded" onClick={() => { navigate("/help") }}>
            <FaPlay className="mr-2" />
            How to Play
          </button>
          )}
        </div>

        {/* Fund Buttons */}
        <div className="flex flex-row md:flex-row gap-3 md:space-x-4 mt-4">

{/* Add Fund Button */}
        {accountStatus=="1"&&(
 <div
 style={{ cursor: "pointer", fontSize: "12px" }}
   onClick={() => {
     navigate("/funds");
   }}
   className="custom-blue text-white text-sm font-medium flex flex-fill justify-content-center items-center py-2 px-2 rounded"
 >
   <FaMoneyBillWave size="30" /> Add Points
 </div>

          )}
          
         


          {/* <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/chart");
            }}
            className="bg-white text-black  text-sm font-medium flex flex-fill justify-content-center items-center py-2 px-2 rounded"
          >
            <FaChartBar size="fs-20" color="green" /> 
           chart
          </div> */}

        
          {/* Withdraw Fund Button */}
          {accountStatus=="1"&&(
            <div
          style={{ cursor: "pointer", fontSize: "12px" }}
            onClick={() => {
              navigate("/withdraw");
            }}
            className="bg-red-600 text-white  flex flex-fill justify-content-center items-center py-2 px-2 rounded"
          >
            <FaHandHoldingUsd size={30} /> Withdraw
          </div>
)}
          
          
        </div>

        {/* Secondary Buttons */}
        {accountStatus!="2"&&(
          <div className="flex flex-row md:flex-row gap-3 md:space-x-4 mt-4">
          {/* Play Star Line Button */}
          {starlineStatus=="On"&&(
 <button className="bg-white hover:bg-yellow-600 text-black font-medium py-2 px-4 rounded" onClick={() => { navigate("/starline", { state: { startLine: true } }) }}>
 <FaPlayCircle size={20} />
 Star Line
</button>

          )}
         
          {/* Website Button */}
          <button  className="bg-white hover:bg-indigo-600 text-black font-medium py-2 px-4 rounded"
          onClick={() => {
            window.location.href = `${playLink}`;
          }}>
            <FaGlobe size={20} />
            Apk
          </button>

          {/* Gali Desawar Button */}
          {galidesawarStatus=="On" &&(
            <button className="bg-white hover:bg-red-600 text-black font-medium py-2 px-4 rounded" onClick={() => { navigate("/starline", { state: { galiDesawar: true } }) }}>
            <FaStreetView size={20} />
            Gali Desawar
          </button>
          )}
          
        </div>
)}
        
      </div>


      <div className="mx-auto rounded-xl overflow-hidden satta-matka-home1">
        {
          loading ? (
            <FaSpinner />
          ) : (
            <div className="satta-matka">
              <div className="md:flex-shrink-0"></div>
              <div className="card-bg">
  {mainGameList.length > 0 ? (
    <>
      {mainGameList.map((game: any, index: number) => (
        <div 
          key={index}
          className="card-new p-2 cursor-pointer hover:bg-gray-100"
          
        >
          <div className="uppercase heading-block tracking-wide text-xl font-bold">
            {game.name}
          </div>
          <div className="flex align-items-center justify-around">
            <div className=" " style={{ fontSize: "12px", padding: "10px", paddingLeft: "25px", paddingRight: "25px" }}>
              <Link to={`/chart/${encodeURIComponent(game.chart_url)}`}>
                <img src={c} alt="chart" width={"50px"} />
              </Link>
            </div>

            <div className="flex">
              <a href="#" className="block mt-1 text-lg leading-tight font-medium text-gray-500 hover:underline">
                {game.result}
              </a>
            </div>
            {accountStatus != "2" ? (
  game.play ? (
    <div className=" " style={{ fontSize: "12px" }}>
      <button
        onClick={(e) => {
          e.stopPropagation(); // Prevents parent div click
          if(game.play){
            navigate("/madhurnight", {
              state: {
                open: game.open,
                id: game.id,
                name: game.name
              }
            });
          }else{
            toast.error("Market Closed")
          }
        }}
      >
        <img src={a} alt="chart" width={"50px"} />
      </button>
    </div>
  ) : (
    <div className=" " style={{ fontSize: "12px" }}>
      <button
        onClick={(e) => {
          e.stopPropagation(); 
          toast.error("Market Closed")
        }}
      >
        <img src={b} alt="chart" width={"50px"} />
      </button>
    </div>
  )
) : (
  <div className=" " style={{ fontSize: "12px", padding: "10px", paddingLeft: "25px", paddingRight: "25px" }}>
              <Link to={`/chart/${encodeURIComponent(game.chart_url)}`}>
                <img src={c} alt="chart" width={"50px"} />
              </Link>
            </div>
)}

          </div>

          <p className="mt-2 text-black">
            Open: {game.open_time}
            {game.open_time === Date.now() ? <b className="text-green-600"></b> : null}
            <span> Closes: {game.close_time}</span>
          </p>
        </div>
      ))}
    </>
  ) : (
    <p>No data found</p>
  )}
</div>

            </div>
          )
        }

      </div>
    </div>
    // </>
  );
};
const styles = {
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure the popup is on top of other content
  },
  popupContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '300px',
  },
  closeButton: {
    marginTop: '10px',
    padding: '8px 16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
  },
};
export default Home;
