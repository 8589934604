import React, { useEffect, useState } from "react";
import singleDigit from "../images/single-digit (1).svg";
import jodiDigit from "../images/jodi-digit.svg";
import singlePanna from "../images/single-panna.svg";
import doublePanna from "../images/double-panna.svg";
import tripplePanna from "../images/tripple-panna.svg";
import halfSangam from "../images/half-sangam.svg";
import fullSangam from "../images/full-sangam.svg"; // Import the image
import { FaArrowLeft } from "react-icons/fa";
import { NavBar2 } from "./NavBar2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "../config";

interface NavBar2Props {
  isFund?: boolean;
  isBidHistory?: boolean;
  isWinHistory?: boolean;
  isHelp?: boolean;
  isMadhurNight?: boolean;
}

export const MadhurNight: React.FC<NavBar2Props> = ({
  isFund,
  isBidHistory,
  isWinHistory,
  isHelp,
  isMadhurNight
}) => {
  const location = useLocation();
  const navigate = useNavigate()
  const [vipStatus, setVipStatus] = useState<Boolean>(false);
  const token = localStorage.getItem("token") || "";

  console.log(location)

  useEffect(() => {
    walletData();
  }, []);

  const walletData = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
          },
        }
      );
      response
        .json()
        .then((data: any) => {
          if(data?.status=="success"){
            setVipStatus(data?.data?.vip_status);
          }
          
        })
        .catch((error: any) => {
          // console.log({ error });
          // alert(error)
        });
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };
  
  return (
    <div className="px-15 bg-white ">
       <div className="navbar-main p-3 mb-6 text-left flex items-center text-white">
                <FaArrowLeft onClick={() => navigate(-1)} cursor="pointer" />
                <button className="ml-3 flex items-center font-bold">
                  {" "}
                  {location.state.name}
                </button>
              </div>
     <div className=" flex flex-row px-15 justify-center">
        <div className="w-1/3 mr-5">
          <button onClick={() => { navigate("/Singledigit", { state: { isSingleDigit: true,open:location?.state?.open, id: location?.state?.id } }) }}>
            <img src={singleDigit} alt="Left Image" className="w-full" />
          </button>
        </div>
        {
          location?.state?.open ? (
            <div className="w-1/3">
              <button onClick={() => {
                navigate("/JodiDigit", { state: { isJodiPanna: true,open:location?.state?.open, id: location?.state?.id } })
              }}>
                <img src={jodiDigit} alt="Right Image" className="w-full" />
              </button>
            </div>
          ) : null
        }

      </div>
      <div className="flex flex-row justify-center">
        <div className="w-1/3 mr-5">
          <button onClick={() => { navigate("/Singlepanna", { state: { isSinglePanna: true,open:location?.state?.open, id: location?.state?.id } }) }}>
            <img src={singlePanna} alt="Left Image" className="w-full" />
          </button>
        </div>


        <div className="w-1/3">
          <button onClick={() => { navigate("/Doublepanna", { state: { isDoubleDigit: true,open:location?.state?.open, id: location?.state?.id } }) }} >
            <img src={doublePanna} alt="Right Image" className="w-full" />
          </button>
        </div>

      </div>
      <div className="flex flex-row justify-center">
        <div className="w-1/3 mr-5">
          <button onClick={() => { navigate("/TripplePanna", { state: { isTripplePanna: true,open:location?.state?.open, id: location?.state?.id } }) }} >
            <img src={tripplePanna} alt="Left Image" className="w-full" />
          </button>
        </div>
        {vipStatus==false&&(
           location?.state?.open ? (
            <div className="w-1/3">
              <button onClick={() => { navigate("/halfSangam", { state: { isHalfSangam: true,open:location?.state?.open, id: location?.state?.id } }) }} >
                <img src={halfSangam} alt="Right Image" className="w-full" />
              </button>
            </div>
          ) :null
        )}
        

      </div>
      {
        vipStatus == false &&(
          location?.state?.open ? (
            <div className="flex flex-row mb-10">
              <div className="w-1/3 mx-auto">
                <button onClick={() => { navigate("/FullSangam", { state:
                   { isFullSangam: true,open:location?.state?.open, id: location?.state?.id } }) }} >
                  <img src={fullSangam} alt="Left Image" className="w-full" />
                </button>
              </div>
  
            </div>
          ) : null
        )
      }
      

    </div>
  );
};

export default MadhurNight;
