import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaBell,
  FaChartBar,
  FaFonticons,
  FaFonticonsFi,
  FaHistory,
  FaHome,
  FaLock,
  FaMoneyBill,
  FaMoneyCheck,
  FaPhone,
  FaQuestionCircle,
  FaShareAlt,
  FaStar,
  FaTrophy,
  FaUser,
  FaWallet,
} from "react-icons/fa";
import {
  WhatsappShareButton,
  TelegramShareButton,
  FacebookShareButton,
  EmailShareButton,
  WhatsappIcon,
  TelegramIcon,
  FacebookIcon,
  EmailIcon,
} from "react-share";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Button, ListItemIcon, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { log } from "console";
import config from '../config';
import c from "../images/vip.png"

const Navbar: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const [bannerMarquee, setBannerMarquee] = useState<string>("");
  const [noticeNumber, setNoticeNumber] = useState<string>("");
  const [accountStatus, setAccountStatus] = useState<string>("");
  const [appLink, setAppLink] = useState<string>("");
  const [shareMessage, setShareMessage] = useState<string>("");
  const [walletValue, setWalletValue] = useState();
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false); // by defualt the dialog box is closed
  const [isShareWindowOpen, setIsShareWindowOpen] = useState(false); // share dialog box is opened
    const [vipStatus, setVipStatus] = useState<Boolean>(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    walletData();
  }, []);

  const walletData = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
          },
        }
      );
      response
        .json()
        .then((data: any) => {
          if(data?.status=="success"){
            setWalletValue(data?.data?.available_points);
            setAccountStatus(data?.data?.account_status);
            setVipStatus(data?.data?.vip_status);
          }
          if(data?.code=="505"){
             handleLogout()
          }
          
        })
        .catch((error: any) => {
          // console.log({ error });
          // alert(error)
        });
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  const items = [
    // { text: "test", icon: <FaWallet size={30} /> },
    { path: "/home", text: "Home", icon: <FaHome /> },
    { path: "/editprofile", text: "My Profile", icon: <FaUser /> },
     { path: "/funds", text: "Add Funds", icon: <FaMoneyBill /> },
    { path: "/withdraw", text: "Withdraw", icon: <FaMoneyCheck /> },
    { path: "/wallet", text: "Wallet Statement", icon: <FaWallet /> },
    { path: "/winhistory", text: "Win History", icon: <FaTrophy /> },
    { path: "/bidhistory", text: "Bid History", icon: <FaHistory /> },
    { path: "/gamerates", text: "Game Rates", icon: <FaChartBar /> },
    { path: "/help", text: "How to Play", icon: <FaQuestionCircle /> },
    { path: "/contactus", text: "Contact Us", icon: <FaPhone /> },
    { path: "/share", text: "Share with Friends", icon: <FaShareAlt /> },
    // { path: '/rateapp', text: "Rate App", icon: <FaStar /> },
    { path: "/ChangePassword", text: "Change Password", icon: <FaLock /> },
    { path: "/logout", text: "Logout", icon: <FaLock /> },
  ];
  const googleItems = [
    // { text: "test", icon: <FaWallet size={30} /> },
    { path: "/home", text: "Home", icon: <FaHome /> },
    { path: "/editprofile", text: "My Profile", icon: <FaUser /> },
    { path: "/wallet", text: "Wallet Statement", icon: <FaWallet /> },
    { path: "/winhistory", text: "Win History", icon: <FaTrophy /> },
    { path: "/bidhistory", text: "Bid History", icon: <FaHistory /> },
    { path: "/gamerates", text: "Game Rates", icon: <FaChartBar /> },
    { path: "/help", text: "How to Play", icon: <FaQuestionCircle /> },
    { path: "/contactus", text: "Contact Us", icon: <FaPhone /> },
    { path: "/share", text: "Share with Friends", icon: <FaShareAlt /> },
    // { path: '/rateapp', text: "Rate App", icon: <FaStar /> },
    { path: "/ChangePassword", text: "Change Password", icon: <FaLock /> },
    { path: "/logout", text: "Logout", icon: <FaLock /> },
  ];
  const inactiveItems = [
    // { text: "test", icon: <FaWallet size={30} /> },
    { path: "/", text: "Home", icon: <FaHome /> },
    { path: "/editprofile", text: "My Profile", icon: <FaUser /> },
    { path: "/contactus", text: "Contact Us", icon: <FaPhone /> },
    { path: "/share", text: "Share with Friends", icon: <FaShareAlt /> },
    // { path: '/rateapp', text: "Rate App", icon: <FaStar /> },
    { path: "/ChangePassword", text: "Change Password", icon: <FaLock /> },
    { path: "/logout", text: "Logout", icon: <FaLock /> },
  ];

  const handleItemClick = (item: string) => {
    if (item === "/logout") {
      setIsLogoutDialogOpen(true);
    } else if (item === "/share") {
      setIsShareWindowOpen(true);
    } else {
      navigate(item);
    }
    toggleSidebar()
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch({
      type: "LOGIN_FAILURE",
      payload: { token: null, isLoggedIn: false },
    });
    navigate("login");
  };




  useEffect(() => {
    fetchAppDetails();
    
  }, []);
  useEffect(() => {
    fetchUserDetails();
    
  }, []);

  const fetchAppDetails = async () => {
    try {
      const response = await axios.post(
        `${config.baseUrl}${config.appDetailsEndpoint}`,
        "", // Sending a blank string in the body as specified
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
      if(response.data.data.banner_marquee!=""){
        setBannerMarquee(response.data.data.banner_marquee);
        setAppLink(response.data.app_link)
        setShareMessage(response.data.share_message)

      }else{
        setBannerMarquee(config.appName);
      }
        

    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  };
  const fetchUserDetails = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userDetailsEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
          },
        }
      );
      response
        .json()
        .then((data: any) => {
          setNoticeNumber(data?.data?.pending_noti);
        })
        .catch((error: any) => {
          // console.log({ error });
          // alert(error)
        });
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  
  return (
    <div className="navbar-main p-3 bg-blue-800">
      <div className="wallet-row-reverse container mx-auto flex items-center justify-between">
        {vipStatus==true &&(
          <img className="ml-1" src={c} alt="vip" width={"35px"} />
        )}
        {accountStatus!="2"&&(
 <div
 style={{ cursor: "pointer" }}
 onClick={() => {
   navigate("Wallet");
 }}
 className="text-white text-sm font-medium flex items-center"
>
 <FaWallet size={30} /> {walletValue}
</div>
        )}
       {accountStatus=="1"&&(
        <div 
          style={{ cursor: "pointer", position: "relative" }} 
          onClick={() => navigate("/Notice")}
          className="text-white text-sm font-medium flex items-center"  >
      {/* Notification Icon */}
      <FaBell size={30} />
      {/* Notification Badge */}
      {noticeNumber !="0" && (
        <span className="absolute top-0 right-3 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
          {noticeNumber}
        </span>
       )} 
    </div>
       )}
        
        <div className="text-white mt-1.5 text-md font-medium overflow-hidden">
          <div className="marquee font-bold">{bannerMarquee}</div>
        </div>

        <div onClick={toggleSidebar} className="lg:flex items-center">
          <span className="text-white pr-2 flex items-center justify-between">
            <FaBars />
          </span>
        </div>
      </div>
      <div className="app-container overflow-7 ">
        {/* Your existing app content */}
        <Drawer anchor="left" open={isSidebarOpen} onClose={toggleSidebar}>
          <div className="custom-color mx-auto w-500 text-white">
            <List className="custom-color mx-auto w-500">
            {accountStatus == "1" ? (
  items.map((item, index) => (
    <ListItem
      button
      key={index}
      onClick={() => handleItemClick(item.path)}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.text} />
    </ListItem>
  ))
) : accountStatus=="4"?(
  googleItems.map((item, index) => (
    <ListItem
      button
      key={index}
      onClick={() => handleItemClick(item.path)}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.text} />
    </ListItem>
  ))
):(
  inactiveItems.map((item, index) => (
    <ListItem
      button
      key={index}
      onClick={() => handleItemClick(item.path)}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.text} />
    </ListItem>
  ))
)}

            </List>
          </div>
        </Drawer>
      </div>
      <Dialog
        open={isLogoutDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{fontSize:"18px"}} id="alert-dialog-title">Do you really want to logout ?</DialogTitle>

        <DialogActions>
          <Button
            onClick={() => setIsLogoutDialogOpen(false)}
            className=" !text-white bg-danger"
          >
            Cancel
          </Button>
          <Button onClick={handleLogout} autoFocus className="!text-white bg-success">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
      {/* share with your friends */}
      <Dialog
        open={isShareWindowOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Share with Friends</DialogTitle>
        <DialogContent>
          <div className="share-buttons flex justify-evenly gap-3">
            <WhatsappShareButton url={appLink} title={shareMessage}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <TelegramShareButton url={appLink} title={shareMessage}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <FacebookShareButton url={appLink} title={shareMessage}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <EmailShareButton url={appLink} title={shareMessage}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setIsShareWindowOpen(false)}
            className=" !text-red-600"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Navbar;
