// import React, { useState } from "react";
// import { FaEdit, FaRupeeSign } from "react-icons/fa";
// import { useForm } from "react-hook-form";
// import { NavBar2 } from "./NavBar2";
// import { MyDatePicker } from "./MyDatePicker";
// import { useLocation } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";

// interface Bid {
//   game_id?: number;
//   game_type?: string;
//   open_panna: string;
//   bid_points: string;
//   session: string;
// }

// const DoublePanna: React.FC<any> = () => {
//   const DOUBLE_PANNA = [
//     100,
//     110,
//     112,
//     113,
//     114,
//     115,
//     116,
//     117,
//     118,
//     119,
//     122,
//     133,
//     144,
//     155,
//     166,
//     177,
//     188,
//     199,
//     200,
//     220,
//     223,
//     224,
//     225,
//     226,
//     227,
//     228,
//     229,
//     233,
//     244,
//     255,
//     266,
//     277,
//     288,
//     299,
//     300,
//     330,
//     334,
//     335,
//     336,
//     337,
//     338,
//     339,
//     344,
//     355,
//     366,
//     377,
//     388,
//     399,
//     400,
//     440,
//     445,
//     446,
//     447,
//     448,
//     449,
//     455,
//     466,
//     477,
//     488,
//     499,
//     500,
//     550,
//     556,
//     557,
//     558,
//     559,
//     566,
//     577,
//     588,
//     599,
//     600,
//     660,
//     667,
//     668,
//     669,
//     677,
//     688,
//     699,
//     700,
//     770,
//     778,
//     779,
//     788,
//     799,
//     800,
//     880,
//     889,
//     899,
//     900,
//     990
//   ];

//   const [suggestedPannas, setSuggestedPannas] = useState<number[]>([]);


//   const location = useLocation();
//   const token = localStorage.getItem("token") || "";

//   const [bids, setBids] = useState<Bid[]>([]);
//   const { register, handleSubmit, formState: { errors }, reset , getValues , setValue} = useForm<Bid>();

//   const onSubmit = (data: Bid) => {
//     const defaultValues: Partial<Bid> = {
//       game_id: location?.state?.id,
//       game_type: "double_panna"
//     };

//     const bidData = { ...defaultValues, ...data };

//     setBids([...bids, bidData]);
//     reset();
//   };

//   const handleFormSubmit = () => {
//     handleSubmit(onSubmit)();
//   };

//   const submitBid = async () => {
//     const formData = new URLSearchParams();
//     formData.append("game_bids", JSON.stringify({ bids }));

//     const response = await fetch("https://smapidev.co.in/api/Api/place_bid", {
//       method: "POST",
//       body: formData,
//       headers: {
//         token,
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
//       },
//     });
//     response.json().then((data: any) => {
//       setBids([]);
//       toast.error(data.message)
//     }).catch((error: any) => {
//       toast.error(error)
//     });
//   };

//   // Custom validation function to check if open panna exists
//   const validateOpenPanna = (value: string) => {
//     const isValid = DOUBLE_PANNA.includes(parseInt(value));
//     return isValid || "Enter valid panna.";
//   };

//   const suggestPannas = (inputValue: string) => {
//     const filteredPannas = DOUBLE_PANNA.filter(panna => panna.toString().startsWith(inputValue));
//     setSuggestedPannas(filteredPannas);
//   };

//   const setOpenPanna = (panna: number) => {
//     setValue("open_panna" , panna.toString())

//     // reset({ open_panna: panna.toString() });
//     setSuggestedPannas([]);
//   };


//   return (
//     <div className="bg-gray-100 rounded-lg">
//       <ToastContainer />
//       <NavBar2 isDoublePanna={location?.state?.isDoubleDigit} />
//       <div className="container shadow-md w-100 bg-white p-3 rounded-md text-left">
//         <div className="flex">
//           <MyDatePicker />
//         </div>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <div className="container text-left">
//             <h2 className="choose_session font-bold mb-4">Choose Session</h2>
//             <div className="open flex justify-between">
//               <label>
//                 <input defaultChecked={true} type="radio" {...register("session", { required: true })} value="open" /> Open
//               </label>
//               <label>
//                 <input type="radio" {...register("session", { required: true })} value="close" /> Close
//               </label>
//             </div>
//             {errors.session && <span className="text-red-500">Please select a session</span>}
//           </div>
//           <div className="panna-new text-left mt-4">
//             <label htmlFor="digit">Panna</label>
//             <div className="input-group">
//               <span className="input-group-text"><FaEdit /></span>
//               <input
//                 type="text"
//                 className="form-control" {...register("open_panna", { required: true, validate: validateOpenPanna })}
//                 onChange={(e) => suggestPannas(e.target.value)} // Call suggestPannas on input change
//                 maxLength={3} placeholder="Enter Digit" />
//             </div>
//             {errors.open_panna && <span className="text-red-500">{errors.open_panna.message}</span>}
//             {suggestedPannas.length > 0 && (
//               <ul>
//                 {suggestedPannas.map((panna, index) => (
//                   <li className="cursor-pointer" key={index} onClick={() => setOpenPanna(panna)}>{panna}</li>
//                 ))}
//               </ul>
//             )}
//           </div>
//           <div className="panna-new text-left mt-4">
//             <label htmlFor="points">Points</label>
//             <div className="input-group">
//               <span className="input-group-text"><FaRupeeSign /></span>
//               <input type="text" className="form-control" {...register("bid_points", { required: true, pattern: /^[0-9]+$/ })} placeholder="Enter Points" />
//             </div>
//             {errors.bid_points && <span className="text-red-500">Please enter valid points</span>}
//           </div>
//           <div className="Proceed mt-6">
//             <button type="submit" className="btn-proceed bg-blue-800 w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
//           </div>
//         </form>

//         {/* Display bids in a table */}
//         <div className="mt-6">
//           <h2 className="font-bold mb-4">Bids</h2>
//           <table className="table">
//             <thead>
//               <tr>
//                 <th>Session</th>
//                 <th>Digit</th>
//                 <th>Points</th>
//               </tr>
//             </thead>
//             <tbody>
//               {bids.map((bid, index) => (
//                 <tr key={index}>
//                   <td>{bid.session}</td>
//                   <td>{bid.open_panna}</td>
//                   <td>{bid.bid_points}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {/* Submit Button */}
//         <div className="mt-4">
//           <button className="btn-submit bg-blue-800 text-white py-2 px-4 rounded-lg" onClick={submitBid}>Submit</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DoublePanna;






import React, { useEffect, useState } from "react";
import { FaEdit, FaRupeeSign, FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavBar2 } from "./NavBar2";
import { MyDatePicker } from "./MyDatePicker";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import config from '../config';


interface SingleDigitProps {
  isDoublePanna?: boolean;
}

interface Bid {
  game_id?: number;
  game_type?: string;
  open_digit: string;
  bid_points: string;
  session: string;

    open_panna?: string; // or whatever type this should be
    close_panna?: string; // or whatever type this should be
    close_digit?: string; // or whatever type this should be
 
}

const DoublePanna: React.FC<any> = () => {
  const DOUBLE_PANNA = [
    100,
    110,
    112,
    113,
    114,
    115,
    116,
    117,
    118,
    119,
    122,
    133,
    144,
    155,
    166,
    177,
    188,
    199,
    200,
    220,
    223,
    224,
    225,
    226,
    227,
    228,
    229,
    233,
    244,
    255,
    266,
    277,
    288,
    299,
    300,
    330,
    334,
    335,
    336,
    337,
    338,
    339,
    344,
    355,
    366,
    377,
    388,
    399,
    400,
    440,
    445,
    446,
    447,
    448,
    449,
    455,
    466,
    477,
    488,
    499,
    500,
    550,
    556,
    557,
    558,
    559,
    566,
    577,
    588,
    599,
    600,
    660,
    667,
    668,
    669,
    677,
    688,
    699,
    700,
    770,
    778,
    779,
    788,
    799,
    800,
    880,
    889,
    899,
    900,
    990
  ];

  const location = useLocation();
  const token = localStorage.getItem("token") || '';
  const [bids, setBids] = useState<Bid[]>([]);
  const { register, handleSubmit, formState: { errors }, reset, trigger,getValues, setValue } = useForm<Bid>();
  const [suggestedPannas, setSuggestedPannas] = useState<number[]>([]);

  const [availablePoints, setAvailablePoints] = useState<string>("");
   const [remainingPoints, setRemainingPoints] = useState<string>("");
      const [miniBidAmount, setMinimumBidAmount] = useState<string>("");
      const [maxBidAmount, setMaxBidAmount] = useState<string>("");
      const [totalBidAmount, setTotalBidAmount] = useState(0);
        const [showList, setShowList] = useState(false);
        const [showBidList, setShowBidList] = useState(false);
  const onSubmit = (data: Bid) => {
    setShowBidList(true)
    const defaultValues: Partial<Bid> = {
      game_id: location?.state?.id, 
      game_type: "double_panna"
    };

 // Check if the open_digit is a valid triple panna
 if (!DOUBLE_PANNA.includes(parseInt(data.open_digit))) {
  toast.error('Invalid Triple Panna digit. Please select a valid digit.');
  return;
}

  console.log(location.state.open)
    const bidData = { ...defaultValues, ...data };
    setBids([...bids, bidData]);
    reset();
  };
  useEffect(() => {
      const total = bids.reduce((sum, bid) => sum + parseInt(bid.bid_points), 0);
      setTotalBidAmount(total);
      const remaining = parseInt(availablePoints)-total
      setRemainingPoints(remaining.toString())
    }, [bids]);
  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const submitBid = async () => {
    if (bids.length === 0) {
      toast.error('Please add bids before submitting.');
      return;
    }
  
    const formData = new FormData();
    
    // Add open_panna, close_panna, and close_digit to each bid if required
    const modifiedBids = bids.map(bid => ({
      ...bid,
      open_panna: bid.open_panna ?? 'default_value', // fallback to 'default_value' if undefined
      close_panna: bid.close_panna ?? 'default_value', // fallback to 'default_value' if undefined
      close_digit: bid.close_digit ?? 'default_value'// replace with the appropriate value
    }));
  
    formData.append("game_bids", JSON.stringify({ bids: modifiedBids }));
  
    try {
      console.log('Submitting bids:', modifiedBids); // Log bids to check if they're valid
      
      const response = await axios.post(`${config.baseUrl}${config.placeBidEndpoint}`, formData, {
        headers: {
          token,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
        },
      });
  
      console.log('Response:', response.data);
      
      // Check response status to determine success or failure
      if (response.data.status === 'success') {
        toast.success(response.data.message);
                        setShowBidList(false)
                        setBids([]); // Clear bids after successful submission
      } else {
        toast.error(response.data.message || 'Failed to submit bids.');
      }
  
    } catch (error) {
      toast.error('An error occurred while submitting bids. Please try again.');
      
    }
  };
  
  
  

  const validateOpenPanna = (value: string) => {
    const isValid = DOUBLE_PANNA.includes(parseInt(value));
    return isValid || "Enter valid panna.";
  };

  const suggestPannas = (inputValue: string) => {
    if (inputValue.length === 0) {
      setSuggestedPannas([]);
      return;
    }
  
    const filteredPannas = DOUBLE_PANNA.filter((panna) =>
      panna.toString().startsWith(inputValue)
    );
    setSuggestedPannas(filteredPannas);
  };

  const setOpenPanna = (panna: number, currentSession: string) => {
    setValue("open_digit", panna.toString());
    setSuggestedPannas([]);
  };

  const fetchUserStatus = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      
      if (data?.status == "success") {
        setAvailablePoints(data?.data.available_points)
        setRemainingPoints(data?.data.available_points)
        setMaxBidAmount(data?.data.maximum_bid_amount)
        setMinimumBidAmount(data?.data.minimum_bid_amount)
      } else {
        toast.error(data?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching withdraw statement:", error);
      toast.error("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchUserStatus();
  }, []);
const removeBid = (indexToRemove: number) => {
    // Get the bid points before updating state
    const removedBidPoints = parseInt(bids[indexToRemove].bid_points);
  
    // Update the bids list
    setBids((prevBids) => prevBids.filter((_, index) => index !== indexToRemove));
  
    // Update remaining points safely
    setRemainingPoints((prevRemaining) => (parseInt(prevRemaining) + removedBidPoints).toString());
  
    // Hide bid list if it's the last item being removed
    if (bids.length === 1) {
      setShowBidList(false);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
      setValue("open_digit", value, { shouldValidate: true }); // Manually validate input
      trigger("open_digit"); // Trigger validation
    };
  return (
    <div>
      <ToastContainer />
      <NavBar2 isDoublePanna={location?.state?.isDoubleDigit}/>
      <div className="container shadow-md w-100 bg-white p-3 rounded-md text-left">
        {/* Total Balance Card */}
      <div style={{
        border: "1px solid black",
        borderRadius: "8px",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
        width: "90%",
        maxWidth: "400px",
        margin: "20px auto",
      }}
      className="custom-color text-white shadow-md">
          <div className="flex items-center justify-around mb-2">
            <p className="text-lg font-semibold">Total Balance</p>
          </div>
          {isNaN(parseInt(remainingPoints))?(
            <p>Loading...</p>
          ):(<p className="text-2xl font-semibold text-white-800">
            ₹ {remainingPoints}
          </p>)}
        </div>
        <div>
          <MyDatePicker />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container text-left">
            <h2 className="choose_session font-bold mb-4">Choose Session</h2>
            <div className="open flex justify-between">
              <label>
                <input  type="radio" disabled={location.state.open === false} defaultChecked={location.state.open===true} {...register("session", { required: true })} value="open" /> Open
              </label>
              <label>
                <input type="radio" defaultChecked={location.state.open===false} {...register("session", { required: true })} value="close" /> Close
              </label>
            </div>
            {errors.session && <span className="text-red-500">Please select a session</span>}
          </div>
          <div className="panna-new text-left mt-4">
            <label htmlFor="digit">Double Panna</label>
            <div className="input-group">
              <span className="input-group-text"><FaEdit /></span>
              <input
                type="tel"
                className="form-control" {...register("open_digit", 
                  {
                    required: "Please enter double panna",
           validate: value => DOUBLE_PANNA.includes(Number(value)) || "Only double panna allowed"
                  }
                )}
                maxLength={3}
                minLength={3}
                autoComplete="off"
                inputMode="numeric"
                placeholder="Enter Double Panna"
                onFocus={() => setShowList(true)}
          onBlur={() => setTimeout(() => setShowList(false), 200)}
          list="digitSuggestions"
                // onChange={(e) => suggestPannas(e.target.value)} 
                onInput={handleInputChange}
          onChange={handleInputChange}
              />
              <datalist id="digitSuggestions">
        {showList &&
          DOUBLE_PANNA.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
            </div>
            {errors.open_digit && <span className="text-red-500">{errors.open_digit.message}</span>}
           
          </div>
          <div className="panna-new text-left mt-4">
                                <label htmlFor="points">Points</label>
                                <div className="input-group">
                                  <span className="input-group-text"><FaRupeeSign /></span>
                                  <input type="text" className="form-control"
                                   {...register("bid_points", 
                                   { required: "Please Enter Points",
                                    pattern: /^[0-9]*$/,
                                    validate: (value) => {
                                      const bidPoints = parseInt(value);
                                      const total = bidPoints+totalBidAmount;
                                      
                                      if (isNaN(bidPoints)) return "Please enter valid points";
                                      if (total > parseInt(availablePoints))
                                        return `Insufficient points`;
                                      if (bidPoints < parseInt(miniBidAmount))
                                        return `Minimum bid points is ${miniBidAmount}`;
                                      if (bidPoints > parseInt(maxBidAmount))
                                        return `Maximum bid points is ${maxBidAmount}`;
                                      return true;
                                    },
                                   })}
                                    placeholder="Enter Points"
                                    maxLength={8}
                                    onInput={(e) => {
                                      const target = e.target as HTMLInputElement; // Type casting
                              target.value = target.value.replace(/[^0-9]/g, ""); // Restrict to numbers
                                    }}
                                    
                                    />
                                    
                                </div>
                                {errors.bid_points && <span className="text-red-500">{errors.bid_points.message}</span>}
                              </div>
          <div className="Proceed mt-6">
            <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
          </div>
        </form>

        {showBidList===true &&(
                <div  className="mt-6">
                <div className="flex justify-between items-center mb-4">
                 <h2 className="font-bold">Bid List</h2>
                 <h2 className="font-bold">Total Points: {totalBidAmount}/-</h2>
               </div>
                <table className="table">
                  <thead>
                    <tr >
                      <th>Session</th>
                      <th>Digit</th>
                      <th>Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bids.map((bid, index) => (
                      <tr key={index}>
                        <td>{bid.session}</td>
                        <td>{bid.open_digit}</td>
                        <td>{bid.bid_points}</td>
                        <td>
                             <button
                               className="btn btn-danger btn-sm"
                               onClick={() => removeBid(index)}
                             >
                               <FaTimes /> {/* Cross icon */}
                             </button>
                           </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
               </div>
               
               
                       )}
                       {showBidList===true&& (
                         <div className="mt-4">
                <button className="btn-submit bgmatch text-white py-2 w-100 px-4 rounded-lg" onClick={submitBid}>Proceed</button>
               </div>
                       )} 
      </div>
    </div>
  );
};

export default DoublePanna;
