import React, { useEffect, useState } from "react";
import { FaEdit, FaRupeeSign, FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavBar2 } from "./NavBar2";
import { MyDatePicker } from "./MyDatePicker";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import { Console } from "console";
import config from '../config';

interface SingleDigitProps {
  isDoublePanna?: boolean;
}

interface Bid {
  game_id?: number;
  game_type?: string;
  open_digit: string;
  bid_points: string;
  session: string;

    open_panna?: string; // or whatever type this should be
    close_panna?: string; // or whatever type this should be
    close_digit?: string; // or whatever type this should be
 
}

const Jodiigit: React.FC<SingleDigitProps> = ({ isDoublePanna }) => {

  const location = useLocation();
  const Jodidigit = [
    "00","01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
    "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22",
    "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34",
    "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46",
    "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58",
    "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70",
    "71", "72", "73", "74", "75", "76", "77", "78", "79", "80", "81", "82",
    "83", "84", "85", "86", "87", "88", "89", "90", "91", "92", "93", "94",
    "95", "96", "97", "98", "99"
  ];
  const token = localStorage.getItem("token") || '';
  const [bids, setBids] = useState<Bid[]>([]);
  const { register, handleSubmit, formState: { errors }, reset, getValues,trigger, setValue } = useForm<Bid>();
  const [suggestedPannas, setSuggestedPannas] = useState<number[]>([]);
  const [filteredDigits, setFilteredDigits] = useState<string[]>([]);
  const [selectedDigit, setSelectedDigit] = useState<string>("");
  const [availablePoints, setAvailablePoints] = useState<string>("");
  const [remainingPoints, setRemainingPoints] = useState<string>("");
  const [totalBidAmount, setTotalBidAmount] = useState(0);
    const [showList, setShowList] = useState(false);
    const [showBidList, setShowBidList] = useState(false);
     const [miniBidAmount, setMinimumBidAmount] = useState<string>("");
      const [maxBidAmount, setMaxBidAmount] = useState<string>("");
  const onDigitInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    setValue("open_digit", value, { shouldValidate: true }); // Manually validate input
    trigger("open_digit"); // Trigger validation
  };
  
  const handleDigitSelect = (digit: string) => {
    setValue("open_digit", digit); // Update form value
    setSelectedDigit(digit);
    setFilteredDigits([]); // Hide suggestions after selection
  };

  const onSubmit = (data: Bid) => {
    if (!Jodidigit.includes(data.open_digit)) {
      toast.error("Please select a valid Jodi digit from the list.");
      return;
    }
    setShowBidList(true)
    const defaultValues: Partial<Bid> = {
      game_id: location?.state?.id,
      game_type: "jodi_digit",
      session: "open",
    };
  
    const bidData = { ...defaultValues, ...data };
    setBids([...bids, bidData]);
    reset();
    setSelectedDigit(""); // Reset the selected digit
  };
useEffect(() => {
    const total = bids.reduce((sum, bid) => sum + parseInt(bid.bid_points), 0);
    setTotalBidAmount(total);
    const remaining = parseInt(availablePoints)-total
    setRemainingPoints(remaining.toString())
  }, [bids]);
  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const submitBid = async () => {
    if (bids.length === 0) {
      toast.error('Please add bids before submitting.');
      return;
    }
   
  
    const formData = new FormData();
    
    // Add open_panna, close_panna, and close_digit to each bid if required
    const modifiedBids = bids.map(bid => ({
      ...bid,
      open_panna: bid.open_panna ?? 'default_value', // fallback to 'default_value' if undefined
      close_panna: bid.close_panna ?? 'default_value', // fallback to 'default_value' if undefined
      close_digit: bid.close_digit ?? 'default_value'// replace with the appropriate value
    }));
  
    formData.append("game_bids", JSON.stringify({ bids: modifiedBids }));
  
    try {
      console.log('Submitting bids:', modifiedBids); // Log bids to check if they're valid
      
      const response = await axios.post( `${config.baseUrl}${config.placeBidEndpoint}`, formData, {
        headers: {
          token,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
        },
      });
  
      console.log('Response:', response.data);
      
      // Check response status to determine success or failure
      if (response.data.status === 'success') {
        toast.success(response.data.message);
        setShowBidList(false)
        setBids([]); // Clear bids after successful submission
      } else {
        toast.error(response.data.message || 'Failed to submit bids.');
      }
  
    } catch (error) {
      toast.error('An error occurred while submitting bids. Please try again.');
      
    }
  };
  
  
  

  const fetchUserStatus = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      
      if (data?.status == "success") {
        setAvailablePoints(data?.data.available_points)
        setRemainingPoints(data?.data.available_points)
        setMaxBidAmount(data?.data.maximum_bid_amount)
        setMinimumBidAmount(data?.data.minimum_bid_amount)
      } else {
        toast.error(data?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching withdraw statement:", error);
      toast.error("An error occurred while fetching data");
    }
  };

  useEffect(() => { 
    fetchUserStatus();
  }, []);

 
  const removeBid = (indexToRemove: number) => {
    // Get the bid points before updating state
    const removedBidPoints = parseInt(bids[indexToRemove].bid_points);
  
    // Update the bids list
    setBids((prevBids) => prevBids.filter((_, index) => index !== indexToRemove));
  
    // Update remaining points safely
    setRemainingPoints((prevRemaining) => (parseInt(prevRemaining) + removedBidPoints).toString());
  
    // Hide bid list if it's the last item being removed
    if (bids.length === 1) {
      setShowBidList(false);
    }
  };
  const setOpenPanna = (panna: number, currentSession: string) => {
    setValue("open_digit", panna.toString());
    setSuggestedPannas([]);
  };

  return (
    <div>
      <ToastContainer />
      <NavBar2 isJodiPanna={location?.state?.isJodiPanna} />
      <div className="container shadow-md w-100 bg-white p-3 rounded-md text-left">
        {/* Total Balance Card */}
      <div style={{
        border: "1px solid black",
        borderRadius: "8px",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
        width: "90%",
        maxWidth: "400px",
        margin: "20px auto",
      }}
      className="custom-color text-white shadow-md">
          <div className="flex items-center justify-around mb-2">
            <p className="text-lg font-semibold">Total Balance</p>
          </div>
          {isNaN(parseInt(remainingPoints))?(
            <p>Loading...</p>
          ):(<p className="text-2xl font-semibold text-white-800">
            ₹ {remainingPoints}
          </p>)}
        </div>
        <div>
          <MyDatePicker />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="container text-left">
              <h2 className="choose_session font-bold mb-4">Choose Session</h2>
              <div className="open flex justify-between">
                <label>
                  <input   type="radio" {...register("session", { required: true })} value="open" /> Open
                </label>
                <label>
                  <input type="radio" defaultChecked={true} {...register("session", { required: true })} value="close" /> Close
                </label>
              </div>
              {errors.session && <span className="text-red-500">Please select a session</span>}
            </div> */}

            <div className="panna-new text-left mt-4">
                        <label htmlFor="digit">Jodi Digit</label>
                        <div className="input-group">
      <span className="input-group-text">
        <FaEdit />
      </span>
      <input
        type="tel"
        className="form-control"
        {...register("open_digit", 
          { required: true,
             pattern: /^[0-9]{1,2}$/,
            validate: value => Jodidigit.includes(value) || "Only jodi digit allowed"
            })}
        maxLength={2}
        inputMode="numeric"
        placeholder="Enter Jodi Digit"
        autoComplete="off"
        onInput={onDigitInputChange}
        onChange={onDigitInputChange}
        onFocus={() => setShowList(true)}
        
        onBlur={() => setTimeout(() => setShowList(false), 200)}
        list="digitSuggestions"
      />

      <datalist id="digitSuggestions">
        {showList &&
          Jodidigit.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
    </div>
                        {errors.open_digit && <span className="text-red-500">Please enter a jodi digit number</span>}
                        {/* {suggestedPannas.length > 0 && (
                          <ul>
                            {suggestedPannas.map((panna, index) => (
                              <li className="cursor-pointer" key={index} onClick={() => setOpenPanna(panna, getValues().session)}>{panna}</li>
                            ))}
                          </ul>
                        )} */}
                      </div>
          
            <div className="panna-new text-left mt-4">
                        <label htmlFor="points">Points</label>
                        <div className="input-group">
                          <span className="input-group-text"><FaRupeeSign /></span>
                          <input type="text" className="form-control"
                           {...register("bid_points", 
                           { required: "Please Enter Points",
                            pattern: /^[0-9]*$/,
                            validate: (value) => {
                              const bidPoints = parseInt(value);
                  const total = bidPoints+totalBidAmount;
                  
                  if (isNaN(bidPoints)) return "Please enter valid points";
                  if (total > parseInt(availablePoints))
                    return `Insufficient points`;
                  if (bidPoints < parseInt(miniBidAmount))
                    return `Minimum bid points is ${miniBidAmount}`;
                  if (bidPoints > parseInt(maxBidAmount))
                    return `Maximum bid points is ${maxBidAmount}`;
                  return true;
                            },
                           })}
                            placeholder="Enter Points"
                            maxLength={8}
                            onInput={(e) => {
                              const target = e.target as HTMLInputElement; // Type casting
                      target.value = target.value.replace(/[^0-9]/g, ""); // Restrict to numbers
                            }}
                            
                            />
                            
                        </div>
                        {errors.bid_points && <span className="text-red-500">{errors.bid_points.message}</span>}
                      </div>
            <div className="Proceed mt-6">
              <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
            </div>       </form>

        {showBidList===true &&(
         <div  className="mt-6">
         <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold">Bid List</h2>
          <h2 className="font-bold">Total Points: {totalBidAmount}/-</h2>
        </div>
         <table className="table">
           <thead>
             <tr >
               <th>Digit</th>
               <th>Points</th>
             </tr>
           </thead>
           <tbody>
             {bids.map((bid, index) => (
               <tr key={index}>
                 <td>{bid.open_digit}</td>
                 <td>{bid.bid_points}</td>
                 <td>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => removeBid(index)}
                      >
                        <FaTimes /> {/* Cross icon */}
                      </button>
                    </td>
               </tr>
             ))}
           </tbody>
         </table>
        </div>
        
        
                )}
                {showBidList===true&& (
                  <div className="mt-4">
         <button className="btn-submit bgmatch text-white py-2 w-100 px-4 rounded-lg" onClick={submitBid}>Proceed</button>
        </div>
                )} 
      </div>
    </div>
  );
};

export default Jodiigit;



