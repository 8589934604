import React, { useEffect, useState } from "react";
import { NavBar2 } from "./NavBar2";
import { FaArrowLeft, FaLock, FaEye, FaEyeSlash, FaEdit, FaRupeeSign, FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import config from '../config'; 
import { log } from "console";


const Notice: React.FC = () => {
  const [appNotice, setAppNotice] = useState<string>("");
  const [addFundNotice, setAddFundNotice] = useState<string>("");
  const [withdrawNotice, setWithdrawNotice] = useState<string>("");
  const [loading, setLoading] = useState(true)


  const token = localStorage.getItem("token") || '';


  useEffect(() => {
    appDetails()
  }, [])

  const appDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch( `${config.baseUrl}${config.appDetailsEndpoint}`,
        {
          method: "POST",
          headers: {
            token
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
          },
        });

      response.json().then((result: any) => {
        if (result.status == "success") {
          setAppNotice(result?.data.app_notice)
          setAddFundNotice(result?.data.add_fund_notice)
          setWithdrawNotice(result?.data.withdraw_notice)
          readNotice(); 
        }
        console.log(result.status)
        setLoading(false)
      }).catch((error: any) => {
        setLoading(false)

        toast.error(error)
      })

    } catch (error) {
      alert("ERROR IN GAME LIST" + error)
      toast.error("Something went wrong on server")

    }
  }

  const readNotice = async () => {
    const formData = new URLSearchParams();
    formData.append("readnoti", "true");
    try {
      const response = await fetch( `${config.baseUrl}${config.readNotificationEndpoint}`,
        {
          method: "POST",
          headers: {
            token
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
          },
          body: formData
        });

      response.json().then((result: any) => {
        toast.success(result.message);
      }).catch((error: any) => {

        toast.error(error)
      })

    } catch (error) {
      alert("ERROR IN GAME LIST" + error)
      toast.error("Something went wrong on server")

    }
  }


  return (
    <div className="bg-gray-100 rounded-lg">
      <NavBar2 isNotice={true} />
      <div className="container mx-auto gamerates">
        {
          loading ? (
            <>
              <FaSpinner className="center"/>
            </>
          )
            : (
              <>
                {
                 <div>
                    <h2 className="text-2xl font-bold text-center text-blue-950">
                      App Notice
                    </h2>
                    <p >
                      {appNotice}
                    </p>
                    <h2 className="text-2xl font-bold text-center mt-6 text-blue-950">
                      Add Fund Notice
                    </h2>
                    <p>
                      {addFundNotice}
                    </p>
                    <h2 className="text-2xl font-bold text-center mt-6 text-blue-950">
                      Withdraw Notice
                    </h2>
                    <p>
                      {withdrawNotice}
                    </p>
                  </div>
                }

              </>
            )

        }

      </div>
    </div>
  );
};

export default Notice;
