import React, { useEffect, useRef, useState } from 'react';
import { NavBar2 } from './NavBar2';
import { FaHandHoldingUsd, FaMoneyBillWave, FaRocket } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { JsonObjectExpression } from 'typescript';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import config from '../config';
import ScreenshotUploader from './ScreenshotUploader ';
interface TabModel {
  name: string;
  notice: string;
  video: string;
  type: string;
}
interface MethodDetails {
  default_method: string;
  upi_limit: string;
  amount_configuration: string;
  upi: {
    video: string;
    notice: string;
  };
  small_amount_upi: {
    upi_name: string;
    upi_id: string;
    remark: string;
    type: string;
  };
  large_amount_upi: {
    upi_name: string;
    upi_id: string;
    remark: string;
    type: string;
  };
  bank_account: {
    video: string;
    notice: string;
    bank_name: string;
    account_holder_name: string;
    account_no: string;
    ifsc_code: string;
  };
  qr_code: {
    video: string;
    notice: string;
    qr_image: string;
    qr_upi_id: string;
  };
}
interface LocationState {
  points: number;
  defaultMethod: string;
  paymentOptionList: TabModel[];
  methodDetails: MethodDetails;
}
const PaymentOptions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { points, defaultMethod, paymentOptionList, methodDetails } = location.state as LocationState;
  const [selectedTab, setSelectedTab] = useState(0);
const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
const [isLoading, setIsLoading] = useState<boolean>(false);
const qrCodeLink =`upi://pay?pa=${methodDetails.qr_code.qr_upi_id}&tn=${config.appName}\n am=${points}&cu=INR`; 
const qrCodeRef = useRef(null);
const upiId = points > parseInt(methodDetails.upi_limit)?methodDetails.large_amount_upi.upi_id:methodDetails.small_amount_upi.upi_id; // Replace with actual mobile number
    const payeeName = points > parseInt(methodDetails.upi_limit)?methodDetails.large_amount_upi.upi_name:methodDetails.small_amount_upi.upi_name; // Replace with actual name
    const transactionNote = points > parseInt(methodDetails.upi_limit)?methodDetails.large_amount_upi.remark:methodDetails.small_amount_upi.remark;
    let upiType = points > parseInt(methodDetails.upi_limit)?"large_amount_upi":"small_amount_upi";
    const currency = "INR";
    const [isCopied, setIsCopied] = useState(false); // Track if text is copied
    const [isCopiedBankName, setIsCopiedBankName] = useState(false);
    const [isCopiedAccountNumber, setIsCopiedAccountNumber] = useState(false);
    const [isCopiedHolderName, setIsCopiedHolderName] = useState(false);
    const [isCopiedIFSCCode, setIsCopiedIFSCCode] = useState(false);
    const [image, setImage] = useState<string | null>(null); // Store the Base64 image
    const videoRef = useRef<HTMLVideoElement>(null);
    const [message, setMessage] = useState<{
        text: string;
        type: "success" | "error" | "";
      }>({ text: "", type: "" });
      const [qrTransactionId, setQrTransactionId] = useState<string | null>(null); 
      const [bankTransactionId, setBankTransactionId] = useState<string | null>(null); 
      
      const [screenshot, setScreenshots] = useState<string | null>(null); // Store UTR
      const [inputAmount, setInputAmount] = useState<string | null>(null); // Store UTR

      const [paymentStatus, setPaymentStatus] = useState<string>(''); // To store payment status
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null); // For polling
      
  const handleCopy = async (text: string,type:string) => {
  
    try {
      await navigator.clipboard.writeText(text); // Copy text to clipboard
      if(type==='qr'){
        setIsCopied(true); // Set copied state to true
      }else if(type==='bankName'){
        setIsCopiedBankName(true); // Set copied state to true
      }else if(type==='acNumber'){
        setIsCopiedAccountNumber(true); // Set copied state to true
      }else if(type==='holder'){
        setIsCopiedHolderName(true); // Set copied state to true
      }else if(type==='ifsc'){
        setIsCopiedIFSCCode(true); // Set copied state to true
      }
      
      setTimeout(() => {
        setIsCopied(false)
        setIsCopiedAccountNumber(false)
        setIsCopiedBankName(false)
        setIsCopiedHolderName(false)
        setIsCopiedIFSCCode(false)
      }, 2000); // Reset copied state after 2 seconds
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };
  
  function snakeCaseToHumanReadable(str: string): string {
    if(str=="payment_gateway"){
      return "Other Upi"
    }
    return str
      .split('_') // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words with spaces
  }
  const handleUpiPayment = () => {
    
    if (!paymentMethod) {
      toast.error("Please select a payment method.")
      return;
    }
    const transId = generateTransactionId(); // Generate a unique transaction ID
    if (paymentMethod === "other") {
      handleOpenAllApps(); // Open all apps if "Other" is selected
    } else {
      // Redirect based on selected payment method
      const paymentUrl = generatePaymentUrl(paymentMethod,transId);
      window.location.href = paymentUrl;

      startPolling(transId,"upi");
    }
  };
  const handleQrPayment = () => {
    
    if (!screenshot) {
      toast.error("Please Upload Screenshot")
      return;
    }
    if (!qrTransactionId) {
      toast.error("Please Enter Transaction ID")
      return;
    }
    paymentReceive(points.toString(),qrTransactionId,"qr_code",screenshot,"")
  };
  const handleBankPayment = () => {
    
    if (!screenshot) {
      toast.error("Please Upload Screenshot")
      return;
    }
    if (!inputAmount) {
      toast.error("Please Enter Amount")
      return;
    }
    if (!bankTransactionId) {
      toast.error("Please Enter Transaction ID")
      return;
    }
    paymentReceive(inputAmount.toString(),bankTransactionId,"bank_account",screenshot,"")
  };
  const handleGatewayPayment = () => {
    paymentRequest()
  };
  const startPolling = (transactionId: string, type:string) => {
    const interval = setInterval(async () => {
      const status = await checkPaymentStatus(transactionId); // Check payment status
      if (status === 'success' ) {
        clearInterval(interval); // Stop polling
        setPaymentStatus(status); // Update payment status
        setIsLoading(false);
        if(type!='upi'){
          upiType="";
        }

        paymentReceive(points.toString(),transactionId,type,"",upiType)
      }else if(status === 'failed'){
        clearInterval(interval); // Stop polling
        setPaymentStatus(status); // Update payment status
        setIsLoading(false);
      }
    }, 5000); // Poll every 5 seconds

    setPollingInterval(interval);
  };
  const checkPaymentStatus = async (transactionId: string): Promise<string> => {
    // Simulate a backend call to check payment status
    // Replace this with an actual API call to your backend
    return new Promise((resolve) => {
      setTimeout(() => {
        // Simulate a successful payment after 15 seconds
        if (Date.now() % 2 === 0) {
          resolve('success');
        } else {
          resolve('failed');
        }
      }, 1000); // Simulate a 1-second delay
    });
  };

  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval); // Clean up polling on component unmount
      }
    };
  }, [pollingInterval]);

  const paymentReceive = async (amount:string,transID: string, methodName:string,screenshot:string, methodDetails:string) => {
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    const token = localStorage.getItem("token") || "";
    const formData = new FormData();
    formData.append("amount", amount);
    formData.append("method_name", methodName);
    formData.append("screenshot", screenshot);
    formData.append("transaction_id", transID);
    formData.append("status", "success");
    formData.append("method_details", methodDetails);
    try {
      const response = await fetch( `${config.baseUrl}${config.paymentReceiveEndpoint}`, {
        method: "POST",
        body: formData,
        headers: {
          token,
        },
      });

      const data = await response.json();
      console.log(data)
      if(data.status=="success"){
        navigate(-1)
      }
      toast.success(data.message)
    } catch (error) {
      console.error("Error adding funds:", error);
      toast.error("Something went wrong")
    } finally {
      setIsLoading(false);
    }
  };

  const paymentRequest = async () => {
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    const token = localStorage.getItem("token") || "";
    const formData = new FormData();
    formData.append("amount", points.toString());
    formData.append("method_name", paymentOptionList[selectedTab].type);
    try {
      const response = await fetch( `${config.baseUrl}${config.paymentRequestEndpoint}`, {
        method: "POST",
        body: formData,
        headers: {
          token,
        },
      });

      const data = await response.json();
      console.log(data)
      if(data.status=="success"){
        const upiUrl = data.data.upi_url;
        const txnid = data.data.txnid;
        const amount = data.data.amount;
        window.location.href = upiUrl;
      startPolling(txnid,paymentOptionList[selectedTab].type);
      }
      toast.success(data.message)
    } catch (error) {
      console.error("Error adding funds:", error);
      toast.error("Something went wrong")
    } finally {
      setIsLoading(false);
    }
  };


  const handleExtract = (transactionId: string | null, image: string | null,) => {
  
    if(paymentOptionList[selectedTab].name=="qr_code"){
      setQrTransactionId(transactionId);
      const inputElement = document.getElementById(
        "qrTransactionIDInput"
      ) as HTMLInputElement;
      if (inputElement) {
        if(transactionId!=null){
          inputElement.value = transactionId.toString();
        }else{
          setQrTransactionId("")
        }
      
      }
    }else{
      setBankTransactionId(transactionId);
      const inputElement = document.getElementById(
        "bankTransactionIDInput"
      ) as HTMLInputElement;
      if (inputElement) {
        if(transactionId!=null){
          inputElement.value = transactionId.toString();
        }else{
          setBankTransactionId("")
        }
      
      }
    }
    
    setScreenshots(image); // Update UTR state
    
  };
  const generatePaymentUrl = (method: string, transId:string): string => {
    setIsLoading(true)
    let url = "";
    
    
    
    const playStoreUrls = {
      googlePay: "https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user",
      phonePe: "https://play.google.com/store/apps/details?id=com.phonepe.app",
      paytm: "https://play.google.com/store/apps/details?id=com.paytm.android",
    };
  
    switch (method) {
      case "googlePay":
        url = `upi://pay?pa=${upiId}@gpay&pn=${payeeName}&am=${points}&tn=${transactionNote}&cu=${currency}&tr=${transId}`;
      
        break;
      case "phonePe":
        url = `phonepe://pay?pa=${upiId}@phonepe&pn=${payeeName}&am=${points}&tn=${transactionNote}&cu=${currency}&tr=${transId}`;
       
        break;
      case "paytm":
        url = `paytmmp://pay?pa=${upiId}@paytm&pn=${payeeName}&am=${points}&tn=${transactionNote}&cu=${currency}&tr=${transId}`;
       
        break;
      default:
        toast.error("Invalid payment method.")
        setIsLoading(false)
        return "";

    }
  
    // Try opening the app; if it fails, redirect to the Play Store
    const fallbackUrl = playStoreUrls[method];
  
    // Attempt to open the app
    window.location.href = url;
  
    // After a delay, check if the app was opened; if not, redirect to Play Store
    setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 2000); // Delay to check if the app opens

    setIsLoading(false);
    return url;
  };
  const generateTransactionId = () => {
    return `txn_${Date.now()}`; // Example transaction ID
  };
  const handleOpenAllApps = () => {
    setIsLoading(true)
    
    const urls = [
      `googlepay://pay?pa=${upiId}@gpay&pn=${payeeName}&am=${points}&tn=${transactionNote}&cu=${currency}`, // Google Pay
      `phonepe://pay?pa=${upiId}@phonepe&pn=${payeeName}&am=${points}&tn=${transactionNote}&cu=${currency}`, // PhonePe
      `paytmmp://pay?pa=${upiId}@paytm&pn=${payeeName}&am=${points}&tn=${transactionNote}&cu=${currency}` // Paytm
    ];
  
    let appOpened = false;
  
    urls.forEach((url, index) => {
      setTimeout(() => {
        window.location.href = url;
        appOpened = true; // App is launched, no need to check for Play Store
      }, index * 1000); // Opening each app with a 1-second delay
    });
  
    // Fallback to Play Store URLs after a certain period if none of the apps were opened
    setTimeout(() => {
      if (!appOpened) {
        // Only redirect to Play Store if no app opened successfully
        const playStoreUrls = {
          googlePay: "https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user",
          phonePe: "https://play.google.com/store/apps/details?id=com.phonepe.app",
          paytm: "https://play.google.com/store/apps/details?id=com.paytm.android",
        };
  
        // Open Play Store URLs for all apps
        window.location.href = playStoreUrls.googlePay;
        setTimeout(() => {
          window.location.href = playStoreUrls.phonePe;
        }, 1000); // Delay for PhonePe
        setTimeout(() => {
          window.location.href = playStoreUrls.paytm;
        }, 2000); // Delay for Paytm
      }
    }, 5000); // 5-second timeout to check if any app was opened
    setIsLoading(false)
  };
  return (
    <div className='bg-white'>
      <NavBar2 isPaymentOptions={true} />
      <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '100%', overflow: 'hidden' }}>
      {/* Scrollable Tab Headers */}
      <div
        style={{
          display: 'flex',
          overflowX: 'auto', // Enable horizontal scrolling
          gap: '10px',
          paddingBottom: '10px', // Space for scrollbar
        }}
      >
        {paymentOptionList.map((option, index) => (
          <div
            key={option.name}
            style={{
              flex: '0 0 auto', // Prevent tabs from shrinking or growing
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '10px 20px',
              cursor: 'pointer',
              borderBottom: selectedTab === index ? '2px solid blue' : '1px solid #ccc',
              fontWeight:  'bold',
              color: selectedTab === index ? 'blue' : '#333',
              whiteSpace: 'nowrap', // Prevent text from wrapping
            }}
            onClick={() =>{
              setSelectedTab(index)
              setPaymentMethod("")
              setIsLoading(false)
              setScreenshots("")
              setQrTransactionId("")
              setBankTransactionId("")
              setInputAmount("")
            }
               
              }
          >
            <span>{snakeCaseToHumanReadable(option.name)}</span> {/* Name */}
          </div>
        ))}
      </div>
      </div>
      <div
      style={{
        backgroundColor: "#FFF5E4",
        border: "1px solid black",
        borderRadius: "8px",
        padding: "15px",
        textAlign: "center",
        fontWeight: "bold",
        width: "80%",
        maxWidth: "400px",
        margin: "20px auto",
        lineHeight: "1.6",
      }}
    >
      <div style={{ color: "blue", marginBottom: "10px" }}>
        {snakeCaseToHumanReadable(paymentOptionList[selectedTab].name)} Notice
      </div>
      <div style={{ color: "#FF0066" }}>
     {paymentOptionList[selectedTab].notice}
        </div>
    </div>
          <div
         style={{ fontSize: "18px",marginLeft: "60px",marginRight:"60px" }}
          
           className="custom-blue text-white text-sm font-medium flex flex-fill justify-content-center items-center py-2 px-2 rounded"
         >
            ₹ {points}
         </div>
        
         {paymentOptionList[selectedTab].name==="upi" && (
          <div className='text-black py-4'>
           <span>
           Select Payment Method
           </span>
           <ul className="ml-3 mr-3 pb-3 text-left">
           <label
        style={{
          display: 'block',
          marginBottom: '10px',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        <input
          type="radio"
          name="paymentMethod"
          value="phonePe"
          checked={paymentMethod === 'phonePe'}
          onChange={(e) => setPaymentMethod(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        PhonePe
      </label>
            
            <label
        style={{
          display: 'block',
          marginBottom: '10px',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        <input
          type="radio"
          name="paymentMethod"
          value="paytm"
          checked={paymentMethod === 'paytm'}
          onChange={(e) => setPaymentMethod(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        Paytm
      </label>
      <label
        style={{
          display: 'block',
          marginBottom: '10px',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        <input
          type="radio"
          name="paymentMethod"
          value="googlePay"
          checked={paymentMethod === 'googlePay'}
          onChange={(e) => setPaymentMethod(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        Google Pay
      </label>
      {/* <label
        style={{
          display: 'block',
          marginBottom: '10px',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        <input
          type="radio"
          name="paymentMethod"
          value="other"
          checked={paymentMethod === 'other'}
          onChange={(e) => setPaymentMethod(e.target.value)}
          style={{ marginRight: '10px'}}
        />
        Other (Open All Apps)
      </label> */}
           
            
          </ul>
          <button
                type="button"
                className="btn-funds rounded-lg custom-color py-2 px-4 text-white flex justify-center mt-4 ml-3 mx-auto w-75 text-center"
                onClick={handleUpiPayment}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Pay"}
              </button>
              <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <video ref={videoRef} controls autoPlay muted width="100%" height="auto" key={paymentOptionList[selectedTab].video}>
        <source src={methodDetails.upi.video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
    </div>
          </div>
         )}
         {paymentOptionList[selectedTab].name==="qr_code" && (
          <div style={{
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            marginLeft:'10px',
            marginRight:'10px'
          }}>
<div className=' mt-3'>
            <h2>Scan And Pay</h2>
      <div style={{
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
          }}>
        <QRCode
          value={qrCodeLink}
          size={200} 
          bgColor="#ffffff" 
          fgColor="#000000" 
          level="H"
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', // Center horizontally
            alignItems: 'center',  gap: '10px',marginTop:10 }}>
        <span style={{ fontFamily: 'monospace', fontSize: '16px' }}>{methodDetails.qr_code.qr_upi_id}</span>
        <button
          onClick={()=>
            handleCopy(methodDetails.qr_code.qr_upi_id,"qr")
          }
          style={{
            padding: '5px 10px',
            backgroundColor: isCopied ? '#4CAF50' : '#007bff', // Green if copied, blue otherwise
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '14px',
          }}
        >
          {isCopied ? 'Copied!' : 'Copy'}
        </button>
    </div>
    <ScreenshotUploader onExtract={handleExtract} />
    
                                  <div className="input-group">
                                    
                                    <input type="text" className="form-control"
                                      placeholder="Enter Transaction ID"
                                     id="qrTransactionIDInput"
                                     value={qrTransactionId?.toString()}
                                      onChange={(value)=>{
                                         setQrTransactionId(value.target.value);
                                      }}
                                      />
                                      
                                  </div>
                                
                                  <button
                type="button"
                className="btn-funds rounded-lg custom-color py-2 px-4 text-white flex justify-center mt-4 ml-3 mx-auto w-75 text-center"
                onClick={handleQrPayment}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Pay"}
              </button>
              <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <video ref={videoRef} controls autoPlay muted width="100%" height="auto" key={paymentOptionList[selectedTab].video}>
        <source src={methodDetails.qr_code.video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
    </div>   
                                  
          </div>
          
          </div>
          
         )}
          {paymentOptionList[selectedTab].name==="bank_account" && (
          <div style={{
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            marginLeft:'10px',
            marginRight:'10px'
          }}>
<div className=' mt-3'>
            
<div
      style={{
        backgroundColor: "#FFF5E4",
        border: "1px solid black",
        borderRadius: "8px",
        fontWeight: "bold",
        textAlign: "left",
        paddingLeft:"10px",
        margin: "20px auto",
        lineHeight: "1.2",
        display :"flex"
      }}
    >

      <div style={{width:'80%', marginTop:'10px'}}>
      <div style={{ color: "blue",fontSize:"14px" }}>
        Bank Name
      </div>
      <div style={{ color: "#FF0066", fontSize:"18px" }}>
          {methodDetails.bank_account.bank_name}
      </div>
      </div>
      
      <button
          onClick={()=>
            handleCopy(methodDetails.bank_account.bank_name,"bankName")
          }
          style={{
            padding: '10px',
            marginTop:'10px',
            marginBottom:'10px',
            backgroundColor: isCopiedBankName ? '#4CAF50' : '#007bff', // Green if copied, blue otherwise
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '14px',
            alignContent:"end",
            justifyContent:"flex-end",
            alignItems: "end"
          }}
        >
          {isCopiedBankName ? 'Copied!' : 'Copy'}
      </button>
    </div>
       
<div
      style={{
        backgroundColor: "#FFF5E4",
        border: "1px solid black",
        borderRadius: "8px",
        fontWeight: "bold",
        textAlign: "left",
        paddingLeft:"10px",
        margin: "20px auto",
        lineHeight: "1.2",
        display :"flex"
      }}
    >

      <div style={{width:'80%', marginTop:'10px'}}>
      <div style={{ color: "blue",fontSize:"14px" }}>
        Account Holder Name
      </div>
      <div style={{ color: "#FF0066", fontSize:"18px" }}>
          {methodDetails.bank_account.account_holder_name}
      </div>
      </div>
      
      <button
          onClick={()=>
            handleCopy(methodDetails.bank_account.account_holder_name,"holder")
          }
          style={{
            padding: '10px',
            marginTop:'10px',
            marginBottom:'10px',
            backgroundColor: isCopiedHolderName ? '#4CAF50' : '#007bff', // Green if copied, blue otherwise
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '14px',
            alignContent:"end",
            justifyContent:"flex-end",
            alignItems: "end"
          }}
        >
          {isCopiedHolderName ? 'Copied!' : 'Copy'}
      </button>
    </div>
       
<div
      style={{
        backgroundColor: "#FFF5E4",
        border: "1px solid black",
        borderRadius: "8px",
        fontWeight: "bold",
        textAlign: "left",
        paddingLeft:"10px",
        margin: "20px auto",
        lineHeight: "1.2",
        display :"flex"
      }}
    >

      <div style={{width:'80%', marginTop:'10px'}}>
      <div style={{ color: "blue",fontSize:"14px" }}>
        Bank Account Number
      </div>
      <div style={{ color: "#FF0066", fontSize:"18px" }}>
          {methodDetails.bank_account.account_no}
      </div>
      </div>
      
      <button
          onClick={()=>
            handleCopy(methodDetails.bank_account.account_no,"acNumber")
          }
          style={{
            padding: '10px',
            marginTop:'10px',
            marginBottom:'10px',
            backgroundColor: isCopiedAccountNumber ? '#4CAF50' : '#007bff', // Green if copied, blue otherwise
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '14px',
            alignContent:"end",
            justifyContent:"flex-end",
            alignItems: "end"
          }}
        >
          {isCopiedAccountNumber ? 'Copied!' : 'Copy'}
      </button>
    </div>
       
<div
      style={{
        backgroundColor: "#FFF5E4",
        border: "1px solid black",
        borderRadius: "8px",
        fontWeight: "bold",
        textAlign: "left",
        paddingLeft:"10px",
        margin: "20px auto",
        lineHeight: "1.2",
        display :"flex"
      }}
    >

      <div style={{width:'80%', marginTop:'10px'}}>
      <div style={{ color: "blue",fontSize:"14px" }}>
        IFSC Code
      </div>
      <div style={{ color: "#FF0066", fontSize:"18px" }}>
          {methodDetails.bank_account.ifsc_code}
      </div>
      </div>
      
      <button
          onClick={()=>
            handleCopy(methodDetails.bank_account.ifsc_code, "ifsc")
          }
          style={{
            padding: '10px',
            marginTop:'10px',
            marginBottom:'10px',
            backgroundColor: isCopiedIFSCCode ? '#4CAF50' : '#007bff', // Green if copied, blue otherwise
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '14px',
            alignContent:"end",
            justifyContent:"flex-end",
            alignItems: "end"
          }}
        >
          {isCopiedIFSCCode ? 'Copied!' : 'Copy'}
      </button>
    </div>
    <ScreenshotUploader onExtract={handleExtract} />
    
                                  <div className="input-group">
                                    
                                    <input type="text" className="form-control"
                                      placeholder="Enter Transaction ID"
                                     id="bankTransactionIDBank"
                                     value={bankTransactionId?.toString()}
                                      onChange={(value)=>{
                                         setBankTransactionId(value.target.value);
                                      }}
                                      />
                                      
                                  </div>
                                  {message && <span className="text-red-500">{message.text}</span>}
                                  <div className="input-group mt-2">
                                    
                                    <input type="text" className="form-control"
                                      placeholder="Enter Amount"
                                     id="inputAmount"
                                      onChange={(value)=>{
                                         setInputAmount(value.target.value);
                                      }}
                                      />
                                      
                                  </div>
                                 
                                  
                                <div style={{maxWidth:'100%'}}>
                                <button
                type="button"
                className="btn-funds rounded-lg custom-color py-2 px-4 text-white flex justify-center mt-4 ml-3 mx-auto w-75 text-center"
                onClick={handleBankPayment}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Pay"}
              </button>
              <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <video ref={videoRef} controls autoPlay muted width="100%" height="auto" key={paymentOptionList[selectedTab].video}>
        <source src={methodDetails.bank_account.video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
    </div>
                                </div>
          </div>
          </div>
          
         )}
         {paymentOptionList[selectedTab].name==="payment_gateway" && (
          <div style={{
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            marginLeft:'10px',
            marginRight:'10px'
          }}>
<div className=' mt-3'>
           
                                  <button
                type="button"
                className="btn-funds rounded-lg custom-color py-2 px-4 text-white flex justify-center mt-4 ml-3 mx-auto w-75 text-center"
                onClick={handleGatewayPayment}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Pay"}
              </button>
              <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <video ref={videoRef} controls autoPlay muted width="100%" height="auto" key={paymentOptionList[selectedTab].video}>
        <source src={paymentOptionList[selectedTab].video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
    </div>   
                                  
          </div>
          
          </div>
          
         )}
          
    </div>
  );
};


export default PaymentOptions;


