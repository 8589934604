import React, { useEffect, useState } from "react";
import { FaCoins, FaMobileAlt, FaGoogle, FaPaypal } from "react-icons/fa";
import { NavBar2 } from "./NavBar2";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import j from "../images/j.png"
import k from "../images/k.png"
import l from "../images/l.png"
import b from "../images/banka.png"
import config from '../config';

// Define the type for a payment method
interface PaymentMethod {
  label: string;
  key: string;
  value: string;
}
type WithdrawStatement = {
  id: string;
  amount: string;
  status: string;
  date: string;
  created_at:string;
  trans_status:string;
  trans_msg:string;
  points:string;
};

const Withdraw: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const [openTime, setOpenTime] = useState<string>("");
  const [closeTime, setCloseTime] = useState<string>("");
  const [statements, setStatements] = useState<WithdrawStatement[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [points, setPoints] = useState<string>("");
  const [notice, setNotice] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [minimumWithdraw, setMinimumWithdraw] = useState<string>("");
  const [maxWithdraw, setMaxWithdraw] = useState<string>("");
  const [availablePoints, setAvailablePoints] = useState<string>("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>("");
  const navigate = useNavigate();
  const [transactionsData, setTransactionsData] = useState<ResponseType | null>(
      null
    );

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userDetailsEndpoint}`,
        {
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
          },
        }
      );

      const data = await response.json();
      const {
        phonepe_mobile_no,
        gpay_mobile_no,
        bank_account_no,
        paytm_mobile_no,
      } = data.data;

      console.log(data.data);

      // Updated to include the key in the PaymentMethod object
      const availableMethods: PaymentMethod[] = [
        { label: "PhonePe No", key: "phonepe_mobile_no", value: phonepe_mobile_no },
        { label: "GPay No", key: "gpay_mobile_no", value: gpay_mobile_no },
        { label: "Account No", key: "bank_account_no", value: bank_account_no },
        { label: "Paytm No", key: "paytm_mobile_no", value: paytm_mobile_no },
      ].filter((method) => method.value);

      setPaymentMethods(availableMethods);
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const userStatus = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
          },
        }
      );

      const data = await response.json();
      

      // Updated to include the key in the PaymentMethod object
      

      setMinimumWithdraw(data?.data.minimum_withdraw);
      setMaxWithdraw(data?.data.maximum_withdraw)
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    userStatus();
  }, []);


  const createPayment = async () => {
    if(points==""){
      setError("Amount is required");
      return
    }
    if(points>availablePoints){
      toast.error("Insufficient Balance")
      return
    }
    if(points<minimumWithdraw){
      toast.error("Minimum withdraw amount is "+minimumWithdraw)
      return
    }
    if(points>maxWithdraw){
      toast.error("Maximum withdraw amount is "+maxWithdraw)
      return
    }
    const formData = new URLSearchParams();
    formData.append("points", points);
    formData.append("method", selectedPaymentMethod); // This now sends the key (e.g., "phonepe_mobile_no")

    try {
      const response = await fetch(
        `${config.baseUrl}${config.withdrawEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData,
        }
      );
      const data = await response.json();
      console.log(data);
      if(data.status=="success"){
        toast.success(data.message)
      }else{
        toast.error(data.message)
      }
      
    } catch (error) {
      console.error("Failed to create payment:", error);
    }
  };



  const fetchAppDetails = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.appDetailsEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      
      if (data?.status == "success") {
    
        setNotice(data?.data.withdraw_notice)
      } else {
        toast.error(data?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching withdraw statement:", error);
      toast.error("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchAppDetails();
  }, []);

  const fetchWithdrawStatement = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.withdrawStatementEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log(data.data.statement)
      
      if (data?.status == "success") {
       // toast.success(data?.message);
        setStatements(data.data.statement);
        setAvailablePoints(data.data.available_points)
        setOpenTime(data.data.withdraw_open_time)
        setCloseTime(data.data.withdraw_close_time)
      } else {
        toast.error(data?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching withdraw statement:", error);
      toast.error("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchWithdrawStatement();
  }, []);

  return (
    <div className="px-15">
      <NavBar2 isWithdraw={true} />
      {/* Total Balance Card */}
      <div style={{
        border: "1px solid black",
        borderRadius: "8px",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
        width: "90%",
        maxWidth: "400px",
        margin: "20px auto",
      }}
      className="custom-color text-white shadow-md">
          <div className="flex items-center justify-around mb-2">
            <p className="text-lg font-semibold">Total Balance</p>
          </div>
          <p className="text-2xl font-semibold text-white-800">
            ₹ {availablePoints}
          </p>
        </div>

      <div className="container py-1">
        <h2 className="Withdraw Open text-left mb-2 text-custom">
          Withdraw Open Time : {openTime}
        </h2>
        <h2 className="Withdraw Open text-left mb-2 text-custom">
          Withdraw Close Time : {closeTime}
        </h2>
        <h2 className=" text-left text-xl mb-2 text-custom font-bold">
          Payment Method
        </h2>
      </div>

      <div className="container flex overflow-x-auto space-x-4">
        <button
          className="flex flex-col items-center space-y-1 border border-blue-900 text-blue-500 px-4 py-2 rounded-md pl-4"
          onClick={() => navigate("/bank-details")}
        >
           <img src={b} alt="" width={60} /> <span>Bank</span>
        </button>
        <button
          className="flex flex-col items-center space-y-1 border border-green-500 text-green-500 px-4 py-2 rounded-md pl-4"
          onClick={() => navigate("/phonepe")}
        >
         <img src={k} alt="" width={60} /> <span>PhonePe</span>
        </button>
        <button
          className="flex flex-col items-center space-y-1 border border-yellow-500 text-yellow-500 px-4 py-2 rounded-md pl-4"
          onClick={() => navigate("/gpay")}
        >
          <img src={j} alt="" width={60} /> <span>Google Pay</span>
        </button>
        <button
          className="flex flex-col items-center space-y-1 border border-indigo-500 text-indigo-500 px-4 py-2 rounded-md pl-4"
          onClick={() => navigate("/paytm")}
        >
          <img src={l} alt="" width={60} /> <span>Paytm</span>
        </button>
      </div>



      
      <div
      style={{
        backgroundColor: "#FFF5E4",
        border: "1px solid black",
        borderRadius: "8px",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
        width: "90%",
        maxWidth: "400px",
        margin: "20px auto",
      }}
    >
      <div style={{ color: "blue", marginBottom: "5px" }}>
        !!Withdraw Notice!!
      </div>
      <div style={{ color: "#FF0066" }}>
        {notice}
      </div>
      </div>

      <div className="container mt-4 text-left">
        <label
          htmlFor="amount"
          className="block text-xl font-medium text-custom"
        >
          Withdraw Funds
        </label>
        <input
        required
          type="number"
          name="amount"
          id="amount"
          value={points}
          onChange={(e) => setPoints(e.target.value)}
          placeholder="Enter Amount"
          className="mt-1 p-2 block w-full border border-black-800 rounded-md text-custom"
        />
{error && <p className="text-red-500 text-sm">{error}</p>}
        <h2 className="mt-2 text-xl text-custom">
          Minimum Withdraw Amount: {minimumWithdraw}
        </h2>
        <h2 className="font-bold text-custom mt-4 text-xl">
          Select Payment Method
        </h2>
        <select
          className="mt-1 p-2 block w-full border border-black-800 rounded-md text-custom"
          value={selectedPaymentMethod}
          onChange={(e) => setSelectedPaymentMethod(e.target.value)}
        >
          <option value="">{paymentMethods.length>0?"Select a payment method":"Add payment method"}</option>
          {paymentMethods.map((method, index) => (
            <option key={index} value={method.key}>
              {method.label} ({method.value})
            </option>
          ))}
        </select>

        <div className="mt-4 flex flex-col sm:flex-row justify-between items-start w-full">
          <button
            className="w-100 custom-color hover:bg-blue-600 text-white font-medium py-3 px-4 rounded-md  sm:w-auto w-full"
            onClick={createPayment}
          >
            Submit
          </button>
        </div>
      </div>
      <ToastContainer/>


      <div className="container mt-4">
        {statements?.length > 0 ? (
          statements.map((item, index) => (
            <div key={index}>
              <div
                className="card mb-3"
                style={{
                  border: "1px solid #d1d9e6",
                  backgroundColor: "#f7faff",
                  borderRadius: "8px",
                  maxWidth: "96%",
                  margin: "auto",
                }}
              >
                <div className="card-body p-3">
                  {/* Game time and type */}
                  <h6
                    className="mt-1"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "start",
                    }}
                  >
                   {item.trans_msg}
                  </h6>

                 

                  {/* Bid points and digit/panna */}
                  <div className="d-flex justify-content-between mt-2">
                    <span style={{ fontSize: "14px" }}>
                      <strong>{item.points}</strong> 
                    </span>
                    <span style={{ fontSize: "14px" }}>
                      <strong>{item.trans_status}</strong>{" "}
                    
                    </span>
                  </div>

                  {/* Date and time */}
                  <p
                    className="mb-0"
                    style={{
                      fontSize: "12px",
                      color: "#6c757d",
                      textAlign: "start",
                      marginTop: "10px",
                    }}
                  >
                    {new Date(item.created_at).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center mt-4">
          <img
            src="https://img.freepik.com/premium-vector/no-data-concept-illustration_634196-28497.jpg?semt=ais_hybrid" // Replace with your image path
            alt="No Data"
            style={{ width: "150px", height: "150px", margin: "auto" }}
          />
          <p style={{ color: "#6c757d", marginTop: "10px" }}>No data available</p>
        </div>
      )}
      </div> 
    </div>
  );
};

export default Withdraw;