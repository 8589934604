import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FaArrowLeft,FaEdit,FaRupeeSign,FaTimes } from 'react-icons/fa';
import config from '../../config';

const StarBettingInterface = () => {
    const singleDigitSuggestions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const singlePannaSuggestions = ['120', '123', '124', '125', '126', '127', '128', '129', '130',
    '134', '135', '136', '137', '138', '139', '140', '145', '146', '147', '148', '149', '150', '156', '157', '158', '159', '160',
    '167', '168', '169', '170', '178', '179', '180', '189', '190', '230', '234', '235', '236', '237', '238', '239', '240',
    '245', '246', '247', '248', '249', '250', '256', '257', '258', '259', '260', '267', '268', '269', '270', '278', '279',
    '280', '289', '290', '340', '345', '346', '347', '348', '349', '350', '356', '357', '358', '359', '360', '367', '368',
    '369', '370', '378', '379', '380', '389', '390', '450', '456', '457', '458', '459', '460', '467', '468', '469', '470',
    '478', '479', '480', '489', '490', '560', '567', '568', '569', '570', '578', '579', '580', '589', '590', '670', '678',
    '679', '680', '689', '690', '780', '789', '790', '890'];

    const doublePannaSuggestions = ['100', '110', '112', '113', '114', '115', '116', '117', '118', '119', '122', '133', '144',
    '155', '166', '177', '188', '199', '200', '220', '223', '224', '225', '226', '227', '228', '229', '233', '244', '255',
    '266', '277', '288', '299', '300', '330', '334', '335', '336', '337', '338', '339', '344', '355', '366', '377', '388',
    '399', '400', '440', '445', '446', '447', '448', '449', '455', '466', '477', '488', '499', '500', '550', '556', '557',
    '558', '559', '566', '577', '588', '599', '600', '660', '667', '668', '669', '677', '688', '699', '700', '770', '778',
    '779', '788', '799', '800', '880', '889', '899', '900', '990'];
    const triplePannaSuggestions = ['000', '111', '222', '333', '444', '555', '666', '777', '888', '999'];

  const token = localStorage.getItem("token") || "";
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState<boolean>(false); // State to control suggestion visibility
  const [suggestions, setSuggestions] = useState<string[]>([]); 
    const [doublePanna, setDoublePanna] = useState('');
    const [triplePanna, setTriplePanna] = useState('');
    const [singlePanna, setSinglePanna] = useState('');
    const [singleDigit, setSingleDigit] = useState('');
    const [amount, setAmount] = useState('');
    const [bidError, setBidError] = useState('');
    const [pointError, setPointError] = useState('');
    const [bids, setBids] = useState<any[]>([]); // Array to store multiple bids
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
      const navigate = useNavigate()
      const [availablePoints, setAvailablePoints] = useState<string>("");
    const [remainingPoints, setRemainingPoints] = useState<string>("");
      const [miniBidAmount, setMinimumBidAmount] = useState<string>("");
      const [maxBidAmount, setMaxBidAmount] = useState<string>("");
      const [totalBidAmount, setTotalBidAmount] = useState(0);
        const [showList, setShowList] = useState(false);
        const [showBidList, setShowBidList] = useState(false);
      const id = queryParams.get('id') || 'Unknown';
    const name = queryParams.get('name') || 'Unknown';
    const game = queryParams.get('gamee') || 'Unknown';

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('game_bids', JSON.stringify({ bids }));

        console.log(Array.from(formData.entries())); // Check the form data in the console

        try {
            const response = await axios.post( `${config.baseUrl}${config.starlinePlaceBidEndpoint}`, formData, {
                headers: {
                   token
                },
            });
            setShowBidList(false)
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.log('Response:', response.data);
            setBids([]); // Clear the bids after successful submission
        } catch (error) {
            toast.error('Failed to submit bids. Please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.error('Error:', error);
        }
    };

    const addBid = () => {
    
        let digit = '';
        
        // Determine which digit type to check based on the game name
        if (name === 'double_panna') {
            digit = doublePanna;
            if (digit.trim() === '') {
                setBidError("Please Enter Double Panna")
                return;
            }
            if (!doublePannaSuggestions.includes(digit)) {
                setBidError("Invalid Double Panna")
                return;
            }
        } else if (name === 'triple_panna') {
            digit = triplePanna;
            if (digit.trim() === '') {
                setBidError("Please Enter Triple Panna")
                return;
            }
            if (!triplePannaSuggestions.includes(digit)) {
                setBidError("Invalid Triple Panna")
                return;
            }
        } else if (name === 'single_panna') {
            digit = singlePanna;
            if (digit.trim() === '') {
                setBidError("Please Enter Single Panna")
                return;
            }
            if (!singlePannaSuggestions.includes(digit)) {
                setBidError("Invalid Single Panna")
                return;
            }
        } else if (name === 'single_digit') {
            digit = singleDigit;
            if (digit.trim() === '') {
                setBidError("Please Enter Single Digit")
                return;
            }
        }
        
        
        const bidPoints = parseInt(amount);
        const total = bidPoints+totalBidAmount;
        // Validation checks
        if (amount.trim() === '') {
            setPointError("Please enter valid points")
            return;
        }
        if (total > parseInt(availablePoints)){
            setPointError("Insufficient points");
            return;
        }
        if (bidPoints < parseInt(miniBidAmount)){
            setPointError("Minimum bid points is "+miniBidAmount);
            return;
        }
        if (bidPoints > parseInt(maxBidAmount)){
            setPointError("Maximum bid points is "+maxBidAmount)
            return;
        }  

        setPointError("")
        // Check if the digit entered matches the suggested values
        let validDigit = false;
        if (name === 'single_digit') {
            validDigit = singleDigitSuggestions.includes(digit);
        } else if (name === 'single_panna') {
            validDigit = singlePannaSuggestions.includes(digit);
        } else if (name === 'double_panna') {
            validDigit = doublePannaSuggestions.includes(digit);
        } else if (name === 'triple_panna') {
            validDigit = triplePannaSuggestions.includes(digit);
        }
    
        // If the digit is not valid, show an error
        if (!validDigit) {
            toast.error('Invalid bet value! Please choose from the suggested options.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setShowBidList(true)
        const bid = {
            game_id: id,
            game_type: name,
            session: 'Open',
            bid_points: amount,
            digit: digit,
            panna: '', // Assuming panna is not used in this context
        };
    
        // Add the bid to the bids array
        setBids([...bids, bid]);
        setDoublePanna('');
        setTriplePanna('');
        setSinglePanna('');
        setSingleDigit('');
        setAmount('');
    };
    
    useEffect(() => {
        const total = bids.reduce((sum, bid) => sum + parseInt(bid.bid_points), 0);
        setTotalBidAmount(total);
        const remaining = parseInt(availablePoints)-total
        setRemainingPoints(remaining.toString())
      }, [bids]);

    const suggest = (inputValue: string, type: string) => {
        let filtered: string[] = [];
    
        // Filter based on inputValue
        if (type === 'single_digit') {
            filtered = singleDigitSuggestions.filter(digit => digit.startsWith(inputValue));
            setSuggestions(filtered);
            setIsSuggestionsVisible(true);
    
            // Hide suggestions if the input matches exactly
            if (filtered.includes(inputValue)) {
                setIsSuggestionsVisible(false);
            }
        } else if (type === 'single_panna') {
            filtered = singlePannaSuggestions.filter(digit => digit.startsWith(inputValue));
            setSuggestions(filtered);
            setIsSuggestionsVisible(true);
    
            // Hide suggestions if the input matches exactly
            if (filtered.includes(inputValue)) {
                setIsSuggestionsVisible(false);
            }
        } else if (type === 'double_panna') {
            filtered = doublePannaSuggestions.filter(panna => panna.startsWith(inputValue));
            setSuggestions(filtered);
            setIsSuggestionsVisible(true);
    
            // Hide suggestions if the input matches exactly
            if (filtered.includes(inputValue)) {
                setIsSuggestionsVisible(false);
            }
        } else if (type === 'triple_panna') {
            filtered = triplePannaSuggestions.filter(panna => panna.startsWith(inputValue));
            setSuggestions(filtered);
            setIsSuggestionsVisible(true);
    
            // Hide suggestions if the input matches exactly
            if (filtered.includes(inputValue)) {
                setIsSuggestionsVisible(false);
            }
        }
    };
    
    

    const handleSuggestionClick = (suggestion: string, type: string) => {
        if (type === 'single_digit') {
            setSingleDigit(suggestion);
        } else if (type === 'single_panna') {
            setSinglePanna(suggestion);
        } else if (type === 'double_panna') {
            setDoublePanna(suggestion);
        } else if (type === 'triple_panna') {
            setTriplePanna(suggestion);
        }
        setIsSuggestionsVisible(false); // Close suggestions on selection
    };
    
  
    const fetchUserStatus = async () => {
        try {
          const response = await fetch(
            `${config.baseUrl}${config.userStatusEndpoint}`,
            {
              method: "POST",
              headers: {
                token,
                "Content-Type": "application/json",
              },
            }
          );
    
          const data = await response.json();
          
          if (data?.status == "success") {
            setAvailablePoints(data?.data.available_points)
            setRemainingPoints(data?.data.available_points)
            setMaxBidAmount(data?.data.maximum_bid_amount)
            setMinimumBidAmount(data?.data.minimum_bid_amount)
          } else {
            toast.error(data?.message || "Failed to fetch data");
          }
        } catch (error) {
          console.error("Error fetching withdraw statement:", error);
          toast.error("An error occurred while fetching data");
        }
      };
    
      useEffect(() => {
        fetchUserStatus();
      }, []);
    
      const removeBid = (indexToRemove: number) => {
        // Get the bid points before updating state
        const removedBidPoints = parseInt(bids[indexToRemove].bid_points);
      
        // Update the bids list
        setBids((prevBids) => prevBids.filter((_, index) => index !== indexToRemove));
      
        // Update remaining points safely
        setRemainingPoints((prevRemaining) => (parseInt(prevRemaining) + removedBidPoints).toString());
      
        // Hide bid list if it's the last item being removed
        if (bids.length === 1) {
          setShowBidList(false);
        }
      };
      
       const handleSingleDigitInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
          setSingleDigit(value);  // Update state with the input value
          setBidError("")
        };
        const handleSinglePannaInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
            setSinglePanna(value);  // Update state with the input value
            setBidError("")
          };
          const handleDoublePannaInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
            setDoublePanna(value);  // Update state with the input value
            setBidError("")
          };
          const handleTriplePannaInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
            setTriplePanna(value);  // Update state with the input value
            setBidError("")
          };
    return (
        <>
         <ToastContainer/>
          <div className="navbar-main p-3 mb-6 text-left flex items-center text-white">
                          <FaArrowLeft onClick={() => navigate(-1)} cursor="pointer" />
                          <button className="ml-3 flex items-center font-bold">
                            {" "}
                            {name.replace("_"," ").toUpperCase()} ({game})
                          </button>
                        </div>
                        {/* Total Balance Card */}
      <div style={{
        border: "1px solid black",
        borderRadius: "8px",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
        width: "90%",
        maxWidth: "400px",
        margin: "20px auto",
      }}
      className="custom-color text-white shadow-md">
          <div className="flex items-center justify-around mb-2">
            <p className="text-lg font-semibold">Total Balance</p>
          </div>
          {isNaN(parseInt(remainingPoints))?(
            <p>Loading...</p>
          ):(<p className="text-2xl font-semibold text-white-800">
            ₹ {remainingPoints}
          </p>)}
        </div>
            <div className="container">
                <div className="card p-3">
                <div className="bgmatch card-header text-center text-white">
                        {/* <h4>{name}</h4> */}
                        <p>{new Date().toDateString()}</p>
                    </div>
                    
                    <div className="card-body">
                        {name === 'double_panna' && (
                            <div className="form-group text-start mt-2">
                                <label className='mb-1' htmlFor="doubblePanna">Enter Doubble Panna</label>
                                <div className="input-group">
                                        <span className="input-group-text">
                                          <FaEdit />
                                        </span>
                                        <input
                                          type="tel"
                                          id="doubblePanna"
                                          className="form-control"
                                          value={doublePanna}
                                          placeholder="Enter Double Panna"
                                          maxLength={3}
                                          autoComplete="off"
                                          onInput={handleDoublePannaInputChange}
                                          onChange={handleDoublePannaInputChange}
                                          onFocus={() => setShowList(true)}
                                          onBlur={() => setTimeout(() => setShowList(false), 200)}
                                          list="suggestions"
                                        />
                                         <datalist id="suggestions">
        {showList &&
          doublePannaSuggestions.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
                                      </div>
                                      {bidError && (
        <span className="text-red-500">{bidError}</span>
      )}
                            </div>
                        )}

                        {name === 'triple_panna' && (
                            <div className="form-group text-start mt-2">
                                <label className='mb-2' htmlFor="triplePanna">Enter Triple Panna</label>
                                <div className="input-group">
                                        <span className="input-group-text">
                                          <FaEdit />
                                        </span>
                                        <input
                                          type="tel"
                                          id="triplePanna"
                                          className="form-control"
                                          value={triplePanna}
                                          placeholder="Enter Triple Panna"
                                          maxLength={3}
                                          autoComplete="off"
                                          onInput={handleTriplePannaInputChange}
                                          onChange={handleTriplePannaInputChange}
                                          onFocus={() => setShowList(true)}
                                          onBlur={() => setTimeout(() => setShowList(false), 200)}
                                          list="suggestions"
                                        />
                                         <datalist id="suggestions">
        {showList &&
          triplePannaSuggestions.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
                                      </div>
                                      {bidError && (
        <span className="text-red-500">{bidError}</span>
      )}
                               
                            </div>
                        )}

                        {name === 'single_panna' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="leftDigit">Enter Single Panna</label>
                                <div className="input-group">
                                        <span className="input-group-text">
                                          <FaEdit />
                                        </span>
                                        <input
                                          type="tel"
                                          id="leftDigit"
                                          className="form-control"
                                          value={singlePanna}
                                          placeholder="Enter Single Panna"
                                          maxLength={3}
                                          autoComplete="off"
                                          onInput={handleSinglePannaInputChange}
                                          onChange={handleSinglePannaInputChange}
                                          onFocus={() => setShowList(true)}
                                          onBlur={() => setTimeout(() => setShowList(false), 200)}
                                          list="suggestions"
                                        />
                                         <datalist id="suggestions">
        {showList &&
          singlePannaSuggestions.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
                                      </div>
                                      {bidError && (
        <span className="text-red-500">{bidError}</span>
      )}
                            </div>
                        )}

                        {name === 'single_digit' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="rightDigit">Enter Single Digit</label>
                                <div className="input-group">
                                        <span className="input-group-text">
                                          <FaEdit />
                                        </span>
                                        <input
                                          type="tel"
                                          className="form-control"
                                          maxLength={1}
                                          value={singleDigit}
                                          inputMode="numeric"
                                          placeholder="Enter Single Digit"
                                          autoComplete="off"
                                          onInput={handleSingleDigitInputChange}
                                          onChange={handleSingleDigitInputChange}
                                          onFocus={() => setShowList(true)}
                                          onBlur={() => setTimeout(() => setShowList(false), 200)}
                                          list="suggestions"
                                        />
                                         <datalist id="suggestions">
        {showList &&
          singleDigitSuggestions.map((digit, index) => (
            <option key={index} value={digit}>
              {digit}
            </option>
          ))}
      </datalist>
                                      </div>
                                      {bidError && (
        <span className="text-red-500">{bidError}</span>
      )}
                            </div>
                        )}
<div className="panna-new text-left mt-4">
                                <label htmlFor="points">Points</label>
                                <div className="input-group mt-1">
                                  <span className="input-group-text"><FaRupeeSign /></span>
                                  <input type="tel" 
                                  className="form-control "
                                  id="amount"
                                    placeholder="Enter Points"
                                    maxLength={8}
                                    value={amount}
                                    onInput={(e) => {
                                      const target = e.target as HTMLInputElement;
                              target.value = target.value.replace(/[^0-9]/g, "");
                   
                              
                                    }}
                                    onChange={(e) => setAmount(e.target.value)}
                                    />
                                    
                                </div>
                                {pointError && (
        <span className="text-red-500">{pointError}</span>
      )}
                              </div>
                        
                    </div>
                </div>

                <button
                    className="bgmatch p-2 w-100 text-white mt-3"
                    onClick={addBid}
                >
                    Add Bid
                </button>



 {showBidList===true &&(
 <div  className="mt-6">
 <div className="flex justify-between items-center mb-4">
  <h2 className="font-bold">Bid List</h2>
  <h2 className="font-bold">Total Points: {totalBidAmount}/-</h2>
</div>
 <table className="table">
   <thead>
     <tr >
       <th>
        {name === 'single_digit'?"Digit":"Panna"}</th>
       <th>Points</th>
     </tr>
   </thead>
   <tbody>
     {bids.map((bid, index) => (
       <tr key={index}>
         <td>{bid.digit}</td>
         <td>{bid.bid_points}</td>
         <td>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => removeBid(index)}
              >
                <FaTimes /> {/* Cross icon */}
              </button>
            </td>
       </tr>
     ))}
   </tbody>
 </table>
</div>


        )}
        {showBidList===true&& (
          <div className="mt-4">
 <button className="btn-submit bgmatch text-white py-2 w-100 px-4 rounded-lg" onClick={handleSubmit}>Proceed</button>
</div>
        )} 

                
            </div>
        </>
    );
};

export default StarBettingInterface;


