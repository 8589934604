import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavBar2 } from "./NavBar2";
import axios from "axios";
import config from '../config';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css"

interface NavBar2Props {
  isFund?: boolean;
  isBidHistory?: boolean;
  isWinHistory?: boolean;
}
interface TabModel {
  name: string;
  notice: string;
  video: string;
  type: string;
}
const token = localStorage.getItem("token") || "";
export const AddFund: React.FC<NavBar2Props> = ({
  isFund,
  isBidHistory,
  isWinHistory,
}) => {
  const [selectedPoints, setSelectedPoints] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availablePoints, setAvailablePoints] = useState<string>("");
  const [inputPoints, setInputPoints] = useState<string>("");
  const [miniDepositAmount, setMiniDepositAmount] = useState<string>("");
  const [maxDepositAmount, setMaxDepositAmount] = useState<string>("");
const navigate = useNavigate();
  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error" | "";
  }>({ text: "", type: "" });
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  const [Data, setData] = useState();

  const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const handlePointSelection = (points: number) => {
    setMessage({ text: "", type: "" });
    setSelectedPoints(points);
    setInputPoints(points.toString());
    const inputElement = document.getElementById(
      "pointsInput"
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = points.toString();
    }
  };

  
  const paymentConfig = async (points: number) => {
    setIsLoading(true);
    setMessage({ text: "", type: "" });

    try {
      const response = await fetch( `${config.baseUrl}${config.paymentConfigEndpoint}`, {
        method: "POST",
        headers: {
          token,
        },
      });

      const data = await response.json();
      console.log(data)
      if(data.status=="success"){

        const availableMethods = data.data.available_methods;
        const paymentOptionList: TabModel[] = [];
        const methodDetails = data.data.available_methods_details;
        let defaultMethod = ""
        if (availableMethods.upi) {
          paymentOptionList.push({
            name: "upi",
            notice: methodDetails.upi.notice,
            video: methodDetails.upi.video,
            type: "",
          });
        }
        
        if (availableMethods.qr_code) {
          paymentOptionList.push({
            name: "qr_code",
            notice: methodDetails.qr_code.notice,
            video: methodDetails.qr_code.video,
            type: "",
          });
        }
        
        if (availableMethods.bank_account) {
          paymentOptionList.push({
            name: "bank_account",
            notice: methodDetails.bank_account.notice,
            video: methodDetails.bank_account.video,
            type: "",
          });
        }
        
        if (availableMethods.payment_gateway) {
          for (const itemObject of availableMethods.payment_gateway) {
            paymentOptionList.push({
              name: "payment_gateway",
              notice: itemObject.notice,
              video: itemObject.video,
              type: itemObject.type,
            });
          }
        }
        defaultMethod = data.data.available_methods_details.default_method
        if(paymentOptionList.length==0){
          window.location.href = `https://wa.me/+${data.data.support_number}`;
        }else{
          navigate('/paymentOptions', {
            state: {
              points,
              defaultMethod,
              paymentOptionList,
              methodDetails
            },
          });
        }
      }
      toast.success(data.message)
    } catch (error) {
      console.error("Error adding funds:", error);
      toast.error("Something went wrong")
    } finally {
      setIsLoading(false);
    }
  };

  const handlePayment = () => {
    const bidPoints = parseInt(inputPoints);
    if (isNaN(bidPoints)) {
      setMessage({type:"error",text:"Please enter valid points"})
      return ;
    }
    if (bidPoints < parseInt(miniDepositAmount)){
      setMessage({type:"error",text:"Minimum deposit points is "+{miniDepositAmount}})
      return ;
    }
    if (bidPoints > parseInt(maxDepositAmount)){
      setMessage({type:"error",text:"Maximum deposit points is "+{maxDepositAmount}})
      return ;
    }
    setMessage({type:"",text:""})
    paymentConfig(bidPoints);
    
    return true;
    
  };

 

  const fetchAppDetails = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.appDetailsEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      
      if (data?.status == "success") {
    
        setData(data.data.add_fund_notice || " ")
      } else {
        toast.error(data?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching withdraw statement:", error);
      toast.error("An error occurred while fetching data");
    }
  };
  useEffect(() => {
    fetchAppDetails();
  }, []);


  
  const fetchUserStatus = async () => {
    try {
      const response = await fetch(
        `${config.baseUrl}${config.userStatusEndpoint}`,
        {
          method: "POST",
          headers: {
            token,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data?.status == "success") {
        setAvailablePoints(data?.data.available_points)
        setMaxDepositAmount(data?.data.maximum_deposit)
        setMiniDepositAmount(data?.data.minimum_deposit)
      } else {
        toast.error(data?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching withdraw statement:", error);
      toast.error("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchUserStatus();
  }, []);

  
  
  return (
    <div>
      <NavBar2 isFund={true} />
      {/* Total Balance Card */}
      <div style={{
        border: "1px solid black",
        borderRadius: "8px",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
        width: "90%",
        maxWidth: "400px",
        margin: "20px auto",
      }}
      className="custom-color text-white shadow-md">
          <div className="flex items-center justify-around mb-2">
            <p className="text-lg font-semibold">Total Balance</p>
          </div>
          {isNaN(parseInt(availablePoints))?(
            <p>Loading...</p>
          ):(<p className="text-2xl font-semibold text-white-800">
            ₹ {availablePoints}
          </p>)}
        </div>
      <div
      style={{
        backgroundColor: "#FFF5E4",
        border: "1px solid black",
        borderRadius: "8px",
        padding: "15px",
        textAlign: "center",
        fontWeight: "bold",
        width: "80%",
        maxWidth: "400px",
        margin: "20px auto",
        lineHeight: "1.6",
      }}
    >
      <div style={{ color: "blue", marginBottom: "10px" }}>
        !!Add Fund Notice!!
      </div>
      <div style={{ color: "#FF0066" }}>
     {Data?Data:"Loading..."}
        </div>
    </div>
      <div className="container">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="panna-new text-left mt-4">
                                  <label htmlFor="points">Points</label>
                                  <div className="input-group">
                                    
                                    <input type="text" className="form-control"
                                      placeholder="Enter Points"
                                     id="pointsInput"
                                      maxLength={8}
                                      onInput={(e) => {
                                        const target = e.target as HTMLInputElement; // Type casting
                                        target.value = target.value.replace(/[^0-9]/g, ""); // Restrict to numbers
                                      }}
                                      onChange={(value)=>{
                                        setInputPoints(value.target.value);
                                      }}
                                      />
                                      
                                  </div>
                                  {message && <span className="text-red-500">{message.text}</span>}
                                </div>
          {/* <input
            type="number"
            className="form-control p-4"
            placeholder="Add Points"
            name="myInput"
            value={selectedPoints || ""}
          /> */}
          <h2 className="text-custom justify-center mt-4">Select Point Amount</h2>
          
          <ul className="flex ml-3 justify-center">
            {[ 500, 1000,2000].map((point) => (
              <li key={point}>
                <button
                  type="button"
                  onClick={() => handlePointSelection(point)}
                  className={`btn-funds rounded-full py-2 px-4 text-white text-left flex justify-left mt-4 ${
                    point !== 500 ? "ml-3" : ""
                  } ${selectedPoints === point ? "bg-yellow-500" : "bg-blue-900"}`}
                >
                  {point}
                </button>
              </li>
            ))}
          </ul>
          <ul className="flex ml-3 justify-center">
            {[3000, 5000, 10000].map((point) => (
              <li key={point}>
                <button
                  type="button"
                  onClick={() => handlePointSelection(point)}
                  className={`btn-funds rounded-full py-2 px-4 text-white text-left flex justify-left mt-4 ${
                    point !== 500 ? "ml-3" : ""
                  } ${selectedPoints === point ? "bg-yellow-500" : "bg-blue-900"}`}
                >
                  {point}
                </button>
              </li>
            ))}
          </ul>
          <ul className="ml-3 pb-3 text-left">
            <li>
              <button
                type="button"
                className="btn-funds rounded-lg custom-color py-2 px-4 text-white flex justify-center mt-4 ml-3 mx-auto w-100 text-center"
                onClick={handlePayment}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Pay"}
              </button>
            </li>
          </ul>
        </form>
        {message.text && (
          <div className={`alert alert-${message.type}`}>
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
};
