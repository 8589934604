import { useEffect, useState } from "react";
import "./whatsaap.css";
import config from '../../config';
import whatsappIcon from "../../images/support.png"; // Add a WhatsApp icon in the `assets` folder


interface ContactDetails {
  whatsapp_no: string;
  mobile_no_1: string;
  mobile_no_2: string;
  email_1: string;
  telegram_no: string;
  withdraw_proof: string;
}


const FloatingButton = () => {
  const [contactDetails, setContactDetails] = useState< ContactDetails | null>(
      null
    );
  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await fetch(
          `${config.baseUrl}${config.appDetailsEndpoint}`
        );
        if (!response.ok) throw new Error("contact details is not fetched");
        const data = await response.json();
        setContactDetails(data.data.contact_details);
        console.log(data.data.contact_details);
      } catch (error) {
        console.error("there is a problem in fetching data", error);
      }
    };
    fetchContactDetails(); // fetchContactDetails calling here
  }, []);
  
  
  return (
    <div className="floating-button"  onClick={() => {
      if (contactDetails) {
        window.location.href = `https://wa.me/+${contactDetails.whatsapp_no}`;
    }}}>
      <img src={whatsappIcon} alt="WhatsApp" />
    </div>
  );
};

export default FloatingButton;
