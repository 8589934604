import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from "axios";
import config from '../config';

const Carousel: React.FC = () => {
  const [image, setImages] = useState<string[]>([]); // Specify the state type as a string array

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    fetchAppDetails();
  }, []);

  const fetchAppDetails = async () => {
    try {
      const response = await axios.post(
        `${config.baseUrl}${config.appDetailsEndpoint}`,
        "", // Sending a blank string in the body as specified
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      if (response.data?.data?.banner) {
        console.log(response.data.data.banner)
        setImages(response.data.data.banner.map((item: { image: string }) => item.image));
      }
    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  };

  return (
    <div className="parent-slider max-w-screen-lg mx-auto ">
  <Slider {...settings}>
    {image.length > 0 ? (
      image.map((image: string, index: number) => (
        <div key={index}>
          <img
            className="w-full object-cover"
            src={image}
            alt=""
          />
        </div>
      ))
    ) : ( 
      <div className="h-full flex items-center justify-center">
        <p>Loading...</p>
      </div>
    )}
  </Slider>
</div>

  );
};

export default Carousel;
