import React, { useState } from "react";
import { useForm } from "react-hook-form";
import logo from "../images/logo512.png";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash, FaLock, FaPhone, FaUser } from "react-icons/fa";
import { MdFormatListNumbered } from "react-icons/md";
import config from '../config';
import { toast } from "react-toastify";

interface FormData {
  full_name: string;
  mobile: string;
  pin: string;
  password: string;
}

const SignupForm: React.FC = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue // set value is imported (from react-hook form is imported from react hook form)
  } = useForm<FormData>();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [showPin, setShowPin] = useState(false);

  const onSubmit = async (data: FormData) => {
    try {
      const formData = new URLSearchParams();
      formData.append("full_name", data.full_name);
      formData.append("mobile", data.mobile);
      formData.append("pin", data.pin);
      formData.append("password", data.password);
      formData.append("version", config.version);
      const mobileNum = data.mobile;

      const response = await fetch( `${config.baseUrl}${config.signupEndpoint}`, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
        },
      });
      response
        .json()
        .then((data: any) => {
          // alert(data.message)
          if(data.status=="success"){
            localStorage.setItem("mobile", formData.get("mobile") || "");
            localStorage.setItem("password", formData.get("password") || "");
            navigate("/VerifyOtp", 
              { 
                state: { 
                  mobile: mobileNum, 
                  from: "signup" 
                }
               });
          }else{
            toast.error(data.message);
          }
          
        })
        .catch((error: any) => {
          console.log({ error });
          alert(error);
        });
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  const gotoLoginPage = () => {
    navigate("/login");
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePin = () => {
    setShowPin(!showPin);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.startsWith("0")) {
      value = value.slice(1); // Remove the leading '0'
    }
    setValue("mobile", value); // Update the value in the form
  };
  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value; // Remove non-numeric characters
  
    setValue("full_name", value); // Update the value in the form
  };
  
  const handleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value= e.target.value.replace(/\D/g, "");
 
  };

  return (
    <div className="container ak mx-auto  p-4 max-w-md  shadow-md text-left login-primary">
      <img src={logo} alt="Logo" className="flex mx-auto" />
      <h2 className="text-2xl font-bold mt-4 mb-4 text-white text-center">
        Register
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label
            htmlFor="full_name"
            className="block text-white text-sm font-medium mb-1"
          >
            Enter your Name
          </label>
          <div className="input-group">
                      <span className="input-group-text ">
                        <FaUser />
                      </span>
                      <input
            type="text"
            id="full_name"
            placeholder="Enter Name"
            {...register("full_name", {
              required: "Name is required",
              validate: (value) => {
                const trimmedValue = value.trim(); // Trim spaces
                setValue("full_name", trimmedValue); // Update state
                return trimmedValue.length > 0 ? true : "Invalid Name";
              },
            })}
            className="form-control w-full px-4 py-2 focus:outline-none focus:border-blue-500"
            onChange={handleChangeName}
          />
        </div>
        {errors.full_name && (
          <span className="text-red-500 text-sm">{errors.full_name.message}</span>
        )}
      </div>


        <div className="mb-4">
          <label
            htmlFor="mobile"
            className="block text-white text-sm font-medium mb-1"
          >
            Mobile No
          </label>
          <div className="input-group">
                      <span className="input-group-text ">
                        <FaPhone />
                      </span>
          <input
            type="tel"
            id="mobile"
            placeholder="Enter Number"
            {...register("mobile", {
              required: true,
              pattern: /^[0-9]*$/,
              minLength: 10,
              maxLength: 10,
            })}
            maxLength={10}
            className="form-control w-full px-4 py-2 border  focus:outline-none focus:border-blue-500"
            onChange={handleChange}
          />
          </div>
          {errors.mobile && errors.mobile.type === "required" && (
            <span className="text-red-500 text-sm">Mobile number is required</span>
          )}
          {errors.mobile && errors.mobile.type === "minLength" && (
            <span className="text-red-500 text-sm">
              Mobile number must be exactly 10 digits
            </span>
          )}
          {errors.mobile && errors.mobile.type === "maxLength" && (
            <span className="text-red-500 text-sm">
              Mobile number must be exactly 10 digits
            </span>
          )}
          {errors.mobile && errors.mobile.type === "pattern" && (
            <span className="text-red-500 text-sm">Mobile number must be valid</span>
          )}
        </div>

        <div className="mb-4 relative">
          <label
            htmlFor="password"
            className="block text-white text-sm font-medium mb-1"
          >
            Password
          </label>
          <div className="input-group">
                      <span className="input-group-text ">
                        <FaLock />
                      </span>
                      <input
            type={showPassword ? "text" : "password"}
            id="password"
            placeholder="Enter Password"
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 4,
                message: "Password must be at least 4 characters",
              },
              maxLength: {
                value: 16,
                message: "Password cannot exceed 16 characters",
              },
            })}
            className="form-control w-full px-4 py-2 focus:outline-none focus:border-blue-500"
            maxLength={16}
            autoComplete="new-password" // 🚀 Prevents browser autofill
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
            type="button"
            onClick={handleTogglePassword}
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>
        {errors.password && <span className="text-red-500 text-sm">{errors.password.message}</span>}
      </div>
        <div className="mb-4 relative">
          <label
            htmlFor="pin"
            className="block text-white text-sm font-medium mb-1"
          >
            Enter your Pin
          </label>
          <div className="input-group">
                      <span className="input-group-text ">
                        <MdFormatListNumbered />
                      </span>
          <input
          
            type={showPin ? "tel" : "password"}
            id="pin"
            placeholder="Enter Pin"
            maxLength={4}
            inputMode="numeric" // Numeric keyboard on mobile
            pattern="[0-9]*"
            {...register("pin", { 
              required: "Please enter a valid PIN", 
              pattern: /^[0-9]*$/ ,
              minLength: {
                value: 4,
                message: "Pin must be 4 digits",
              },
          
            })}
            className="form-control w-full px-4 py-2 border  focus:outline-none focus:border-blue-500"
            onChange={handleChange1}
          />
          </div>
          
          <button
            className="absolute right-3 top-9 text-gray-600"
            type="button"
            onClick={handleTogglePin}
          >
            {showPin ? <FaEye className="mr-2" /> : <FaEyeSlash />}
          </button>
          {errors.pin && (
            <span className="text-red-500 text-sm">{errors.pin.message}</span>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-blue-300 mb-5 text-black py-2 rounded-5 hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
        >
          Sign Up
        </button>

        <a href="#" className="mt-8 mb-2 text-white">
          Already have an Account?
        </a>

        <button
          type="button"
          onClick={gotoLoginPage}
          className="w-full bg-yellow-500 text-black mt-3 py-2 rounded-5 hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default SignupForm;
