import { useParams } from 'react-router-dom';
import { FaArrowLeft, FaWallet } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Chart = () => {
  const { chartLink } = useParams(); // This will extract the 'chartLink' from the URL.

  const imageSrc = decodeURIComponent(chartLink); // Decode the URL-encoded image URL

  console.log(imageSrc); // This should give the correct image URL
   const navigate = useNavigate();

  return (
    <div>
      {/* Header */}
     <div className="navbar-main p-3 text-left flex items-center text-white">
               <FaArrowLeft onClick={() => navigate(-1)} cursor="pointer" />
               <button className="ml-3 flex items-center font-bold">Chart</button>
             </div>
      {/* Image Display */}
      <div style={{ textAlign: "center", marginTop: "" }}>
        <iframe width={"100%"} height={"900"} src={imageSrc} ></iframe>
      </div>
    </div>
  );
};

export default Chart;
